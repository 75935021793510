export function checkIfFilesAreTooBig(files: File[]): boolean {
  let isValid = true;

  if (files) {
    files.forEach((file: File) => {
      const size = file.size / 1024 / 1024;
      if (size > 5) {
        isValid = false;
      }
    });
  }

  return isValid;
}

export function checkIfFilesAreCorrectType(files: File[]): boolean {
  let isValid = true;

  if (files) {
    files.forEach((file) => {
      if (!["application/pdf", "image/jpeg", "image/png"].includes(file.type)) {
        isValid = false;
      }
    });
  }

  return isValid;
}

export function checkUploadFiles(files: File[]) {

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (
        !(
          file.type === "application/pdf" ||
          file.type === "application/x-zip-compressed"
        ) ||
        file.size > 70 * 1024 * 1024
      ) {
        return true;
        
      } else {
        return false;
      }
    }
}
