import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  ButtonGroup,
  Flex,
  Grid,
  GridItem,
  Icon,
  Image,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  OutlineButton,
  PrimaryButton,
} from "../../components/lib/button/Button";
import { BlockTitle } from "../../components/lib/title/Title";
import { MainLayout } from "../../components/MainLayout/MainLayout";
import { Comment } from "../../components/Comment/Comment";
import { SingleCompensationActInformationBlock } from "../../components/SingleCompensationActComponents/SingleCompensationActInformationBlock";
import { ChakraModalProps } from "../../shared/types/modalProps";
import SuccessLogo from "../../images/png/success.png";
import { RootState, useAppSelector } from "../../store";
import {
  attachFilesToSingleSavingsCompensationAct,
  fetchSingleSavingsCompensationAct,
  updateSingleSavingsCompensationAct
} from "../../store/slices/savingsCompensationActs";
import { useEffect, useState } from "react";
import { DownloadFilesModal } from "../../components/Modal/ModalRight/DownloadFilesModal";
import {IFilesAttachResponse} from "../../api/files/types";
import { ReactComponent as Document } from "../../images/svg/sidebar/document.svg";
import { OnboardingByPermission } from "../../components/OnboardingByPermission/OnboardingByPermission";
import { AccessRights } from "../../shared/mock/sidebarContent";
import { CreateCompensationActComponent } from "../../components/CreateCompensationActComponent/CreateCompensationActComponent";

export function SingleCompensationActPage({ goBack, id, dispatch, filesModal, setFilesModal }: any) {
  const { isOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (id) {
      dispatch(fetchSingleSavingsCompensationAct(Number(id)));
    }
  }, [dispatch, id]);

  const { currCompAct } = useAppSelector(
    (state: RootState) => state.savingsCompensationActsReducer
  );
  const { currentContract } = useAppSelector(
    (state: RootState) => state.contracts
  );
  const [files, setFiles] = useState(currCompAct?.files ?? []);

  const fileAttach = (files: File[]) => {
    if (currCompAct) {
      dispatch(attachFilesToSingleSavingsCompensationAct({id: currCompAct.id, files: files})).then((res: { payload: IFilesAttachResponse})=> {
        setFiles((res.payload as IFilesAttachResponse)?.data.files);
      });
    }
  }

  const [dataState, setDataState] = useState({
    period_from: "",
    period_to: "",
    amount: 0,
    comment: "",
  });


  function onChangeData(period_from_value:string, period_to_value: string, amount_value: number) {
    setDataState(dataState => ({ ...dataState, period_from: period_from_value, period_to: period_to_value, amount: amount_value }));
  }

  useEffect(() => {
    if (currCompAct) {
      setFiles(currCompAct.files || []);
      onChangeData(currCompAct.start_date, currCompAct.end_date, Number(currCompAct.amount))
    }
  }, [currCompAct]);

  function handleChangeComment(comment_value: string) {
    setDataState((dataState) => ({
      ...dataState,
      comment: comment_value,
    }));
  }

  return (
    <MainLayout>
      <Flex direction="column" gap={10} p={6}>
        <Flex direction="column" gap={4}>
          <Breadcrumb
            fontSize={14}
            separator={<Box w={1} h={1} bg="#8089A2" borderRadius="50%" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/main">Главная</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink href="/purchase">Закупки</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink href="/purchase/compensation-act">
                Акты об экономии/компенсации
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage color="#8089A2">
              <BreadcrumbLink>
                {`Акт об экономии/компенсации ${currCompAct?.number}`}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Flex justify="space-between" align="center">
            <Flex align="center" gap={4}>
              <ArrowBackIcon boxSize={6} cursor="pointer" onClick={() => goBack("/purchase/compensation-act")} />
              <BlockTitle>{`Акт об экономии/компенсации ${currCompAct?.number}`}</BlockTitle>
            </Flex>

            <ButtonGroup spacing={3}>
              <OutlineButton 
                leftIcon={<Icon as={Document} fill={"#E8363D"} />}
                gap={2}
                onClick={() => setFilesModal(true)}
                >
                Файлы
              </OutlineButton>
              <PrimaryButton
                isDisabled={!currCompAct?.canEdit}
                onClick={() => currCompAct && dispatch(updateSingleSavingsCompensationAct({id: currCompAct?.id, params: dataState}))}
              >
                Записать
              </PrimaryButton>
            </ButtonGroup>
          </Flex>
          <Grid templateColumns="1fr 504px" gap={4}>
            <GridItem>
              {currCompAct?.canEdit ? (
                <CreateCompensationActComponent 
                onChangeData={onChangeData} 
                />
              ) : (
                <SingleCompensationActInformationBlock />
              )}
            </GridItem>
            <GridItem>
              <Comment isEdited={currCompAct?.canEdit} currentComment={currCompAct?.comment} handleChangeComment={handleChangeComment}/>
            </GridItem>
          </Grid>
        </Flex>
        <Box>
          <OnboardingByPermission permission={AccessRights.Purchase_RW} />
        </Box>
      </Flex>

      <SuccessModal isOpen={isOpen} onClose={onClose} />
      <DownloadFilesModal
        title="Файлы"
        active={filesModal}
        onClose={() => setFilesModal(false)}
        receivedFiles={files}
        fileAttach={fileAttach}
        isDisabled={!currCompAct?.canEdit}
      />
    </MainLayout>
  );
}

function SuccessModal(props: Readonly<ChakraModalProps>) {
  const {isOpen, onClose} = props;
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent maxW="360px" gap="24px" px="24px" py="32px">
        <ModalHeader p={0}>
          <Flex direction="column" align="center" gap="24px">
            <Image src={SuccessLogo} boxSize="100px" alt="Success Logo" />
            <BlockTitle>Акт успешно записан</BlockTitle>
          </Flex>
        </ModalHeader>
        <ModalFooter p={0}>
          <PrimaryButton w="full" onClick={onClose}>
            Закрыть
          </PrimaryButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
