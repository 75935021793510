import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
  CellContext,
  ColumnDef,
  createColumnHelper, Row,
} from "@tanstack/react-table";
import {
  Center,
  Flex, Image,
  Input,
  Modal, ModalBody,
  ModalContent, ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text, Textarea,
  useDisclosure
} from "@chakra-ui/react";
import { Table } from "../lib/table/Table";
import { ILimitFenceCardMaterial, ILimitFenceCardMaterials, INewLimitFenceCardMaterial } from "../../api/limitFenceCards/types";
import {RootState, useAppDispatch, useAppSelector} from "../../store";
import styles from "../lib/input/input.module.scss";
import tableStyles from "../lib/table/table.module.scss";
import calendar from "../../images/svg/calendar/calendar.svg";
import {ChakraModalProps} from "../../shared/types/modalProps";
import MainLogo from "../../images/svg/main-logo/main-logo.svg";
import {BlockTitle, Title} from "../lib/title/Title";
import {PrimaryButton} from "../lib/button/Button";
import SuccessLogo from "../../images/png/success.png";
import {addMaterialsToFenceCard, changeCurrentLimitFenceCard, fetchSingleLimitFenceCard, fetchSingleLimitFenceCardMaterials, resetCurrentLimitFenceCard} from "../../store/slices/limitFenceCards";
import close from "../../images/svg/close/close.svg";
import { getSingleLimitFenceCardMaterials } from "../../api/limitFenceCards";
import { formatAmount } from "../../helpers/formatAmount";
import dayjs from "dayjs";

interface IMaterialsTableProps {
  searchValue?: string;
  setMaterialRow?: (material: INewLimitFenceCardMaterial | undefined, index: number | undefined) => void;
  setDisableApprove?: (arg:boolean) => void;
  disabledChange?: boolean;
}

interface IEditModal extends ChakraModalProps {
  row?: Row<INewLimitFenceCardMaterial>;
}

const columnHelper = createColumnHelper<INewLimitFenceCardMaterial>();

const MeasureCell = (info: CellContext<INewLimitFenceCardMaterial, string>) => (
  <Center>{info.renderValue()}</Center>
);

const CenterCell = (text: string) => <Center w="full">{text}</Center>;

const AmountCell = (info: CellContext<INewLimitFenceCardMaterial, string>, setDisable: (arg:boolean) => void, setMaterialRow?: (material: INewLimitFenceCardMaterial | undefined, index: number | undefined) => void, disabledChange?: boolean) => {
  const { row } = info;
  const dispatch = useAppDispatch();
  const {currentLimitFenceCard} = useAppSelector((state) => state.limitFenceCards);
  const [value, setValue] = useState(info.renderValue()!);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;
    let formatValue = rawValue.replace(/[^0-9.]/g, "").replace(/^0+(?!\.)|\.*$/g, "");
    formatValue = formatValue === "" ? "0" : formatValue;
    setValue(formatValue);

    const index = row.index;
    if (index !== undefined && currentLimitFenceCard?.materials) {
      const updatedMaterials: INewLimitFenceCardMaterial[] = [...currentLimitFenceCard?.materials];
      updatedMaterials[index] = {
        ...updatedMaterials[index],
          amount: Number(formatValue),

      };

      if (row.getIsSelected() && setMaterialRow) {
        setMaterialRow(updatedMaterials[index], index);
      }

      const updatedLimitFenceCard = {...currentLimitFenceCard, materials: updatedMaterials};
      dispatch(changeCurrentLimitFenceCard(updatedLimitFenceCard));
    }
  }

  useEffect(() => {
    if (currentLimitFenceCard?.materials) {
      const hasChanges = currentLimitFenceCard.materials.some(
        (material, index) => material.amount !== info.row.original.amount && index === info.row.index
      );
      if (hasChanges) {
        setDisable(true);
      }
    }
  }, [currentLimitFenceCard?.materials, info.row.original.amount, info.row.index, setDisable]);

  useEffect(() => {
    setValue(info.renderValue()!)
  }, [info]);

  return (
    <>
      {!disabledChange ? (
        <div className={styles.wrapper}>
          <div className={styles.container}>
            <div className={`${styles.input_container} ${styles.table_input}`}>
              <input
                value={value}
                onChange={(e) =>
                  setValue(e.target.value.replace(/[^0-9.]/g, ""))
                }
                onBlur={handleBlur}
                onKeyDown={(e) =>
                  e.key === "Enter" && (e.target as HTMLInputElement).blur()
                }
                onClick={(e) => e.stopPropagation()}
                disabled={disabledChange}
              />
            </div>
          </div>
        </div>
      ) : (
        <Text>{info.renderValue()}</Text>
      )}
    </>
  );
};

const PeriodCell = (info: CellContext<INewLimitFenceCardMaterial, string>, disabledChange?: boolean) => {
  const inputStyle = `url(${calendar}) no-repeat 100% 50% content-box`;
  const isDisabled = Number(info.row.original.amount) === 0;
  const dispatch = useAppDispatch();
  const {currentLimitFenceCard} = useAppSelector((state) => state.limitFenceCards);
  const [value, setValue] = useState(isDisabled ? "" : info.row.original.period);

  useEffect(() => {
    setValue(isDisabled ? '' : info.row.original.period);
  }, [info.row.original.period, isDisabled]);

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.showPicker();
  }

  const onDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);

    const index = info.row.index;
    if (index !== undefined && currentLimitFenceCard?.materials) {
      const updatedMaterials: INewLimitFenceCardMaterial[] = [...currentLimitFenceCard?.materials];
      updatedMaterials[index] = {
        ...updatedMaterials[index],
          period: e.target.value,
      };

      const updatedLimitFenceCard = {...currentLimitFenceCard, materials: updatedMaterials};
      dispatch(changeCurrentLimitFenceCard(updatedLimitFenceCard));
    }
  }

  return (
    !disabledChange ? (
      <Input
      h="36px"
      rounded="md"
      type="date"
      borderColor="#E3E7EF"
      onFocus={handleFocus}
      bg={inputStyle}
      id="period"
      value={value}
      onChange={onDateChange}
      className={tableStyles.input_date}
      onClick={(e) => e.stopPropagation()}
      disabled={isDisabled}
    />
    ) : (
      <Text>{dayjs(info.renderValue()).format("DD.MM.YYYY")}</Text>
    )
  )
}

const CommentCell = (info: CellContext<INewLimitFenceCardMaterial, string>, onOpenCommentForm: (e: React.MouseEvent, row: Row<INewLimitFenceCardMaterial>) => void, disabledChange?: boolean) => {
  const isDisabled = Number(info.row.original.amount) === 0

  return (
    disabledChange ? (
      <Text  noOfLines={2}>{info.renderValue()}</Text>
    ) : (
      info.renderValue() ? (
        <Text noOfLines={2} onClick={(e: React.MouseEvent) => onOpenCommentForm(e, info.row)}>{info.renderValue()}</Text>
      ) : (
        <Center color={isDisabled ? "#AAB0C1" : "#2AB6A5"} cursor={isDisabled ? "auto" : "pointer"} onClick={(e: React.MouseEvent) => !isDisabled && onOpenCommentForm(e, info.row)} >Добавить</Center>
      )
    )
  );
}

export function MaterialsTable({searchValue, setMaterialRow, setDisableApprove, disabledChange}: IMaterialsTableProps) {
  const dispatch = useAppDispatch();
  const { currentLimitFenceCard } = useAppSelector(
    (state: RootState) => state.limitFenceCards
  );
  const { currentDirectionLetter } = useAppSelector((state: RootState) => state.directionLetters);
  
  const [filteredMaterials, setFilteredMaterials] = useState(currentLimitFenceCard?.materials);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [row, setRow] = useState<Row<INewLimitFenceCardMaterial>>();
  const [spinnerIsRunning, setSpinnerIsRuning] = useState(true)

  const onOpenCommentForm = useCallback((e: React.MouseEvent, row: Row<INewLimitFenceCardMaterial>) => {
    e.stopPropagation();
    setRow(row);
    onOpen();
  }, [onOpen]);

  const onMaterialTableClick = (row: Row<INewLimitFenceCardMaterial>) => {
    row.toggleSelected();
    setMaterialRow && setMaterialRow(row.getIsSelected() ? undefined : row.original, row.getIsSelected() ? undefined : row.index)
  }

  const setDisable = useCallback((arg:boolean) => {
    if (setDisableApprove) {
      setDisableApprove(arg);
    }
  },[setDisableApprove]);

  useEffect(() => {
    setFilteredMaterials(
      searchValue ?
        currentLimitFenceCard?.materials?.filter((item) =>
          item.material_name.toLowerCase().includes(searchValue.toLowerCase().trim())
        ) : currentLimitFenceCard?.materials
    )
  }, [searchValue, currentLimitFenceCard?.materials]);

  useEffect(() => {
    if (currentDirectionLetter?.limit_card_id) {
      dispatch(fetchSingleLimitFenceCard(currentDirectionLetter?.limit_card_id));
    };
    setSpinnerIsRuning(false);

  }, [dispatch, currentDirectionLetter]);

  useEffect(() => {
    setSpinnerIsRuning(true)
    dispatch(fetchSingleLimitFenceCardMaterials(Number(currentDirectionLetter?.limit_card_id)))
      .then((res: any) => {
        dispatch(addMaterialsToFenceCard(res.payload.data))
      })
      .finally(() => setSpinnerIsRuning(false))
  }, [dispatch, currentDirectionLetter?.limit_card_id])

  const columns = useMemo<Array<ColumnDef<INewLimitFenceCardMaterial, string>>>(
    () => [
      columnHelper.group({
        id: "info",
        columns: [
          columnHelper.accessor("order_number", {
            header: "№",
          }),
          columnHelper.accessor("ssom_order_number", {
            header: "№ ССОМ",
          }),
          columnHelper.accessor("material_name", {
            header: "Номенклатура",
          }),
          columnHelper.accessor("analog_material", {
            header: "Аналог номенклатуры",
          }),
          columnHelper.accessor("unit_of_measurement", {
            header: "Ед. изм.",
            cell: MeasureCell,
          }),
          columnHelper.accessor("indicative_price", {
            header: "Цена индикатива",
            cell: (info) => formatAmount(info.renderValue())
          }),
        ],
      }),

      columnHelper.group({
        id: "tender",
        header: () => CenterCell("Решение по тендеру"),
        columns: [
          columnHelper.accessor("tender_supplier_name", {
            header: "Поставщик",
          }),
          columnHelper.accessor("tender_quantity", {
            header: "Количество",
          }),
          columnHelper.accessor("tender_price", {
            header: "Цена",
            cell: (info) => formatAmount(info.renderValue())
          }),
          columnHelper.accessor("tender_solution", {
            header: "Решение по тендеру",
          }),
        ],
      }),
      columnHelper.group({
        id: "volume",
        header: () => CenterCell("Объёмы"),
        columns: [
          columnHelper.accessor("amount", {
            header: "Количество",
            meta: {cellClassName: !disabledChange ? "changeable" : ""},
            cell: (info) => AmountCell(info, setDisable, setMaterialRow, disabledChange),
          }),
          columnHelper.accessor("period", {
            header: "Период поставки",
            meta: {cellClassName: !disabledChange ? "changeable" : ""},
            cell: (info) => PeriodCell(info, disabledChange),
          }),
          columnHelper.accessor("comment", {
            header: "Комментарий подрядчика",
            cell: (info) => CommentCell(info, onOpenCommentForm, disabledChange),
            meta: {cellClassName: !disabledChange ? "changeable" : ""}
          }),
        ],
      }),
      columnHelper.accessor("inventory_supervisor", {
        header: "Куратор ТМЦ",
      }),
    ],
    [onOpenCommentForm, setMaterialRow, setDisable]
  );

  return (
    <>
      {spinnerIsRunning ? (
        <Flex justifyContent="center">
          <Spinner alignSelf="center" size="xl" />
        </Flex>
      ) : (
        <>
          <Table
            data={filteredMaterials ?? []}
            columns={columns}
            rowClick={onMaterialTableClick}
            dblClickDisabled={true}
          />
          <EditModal isOpen={isOpen} onClose={onClose} row={row} />
        </>
      )}
    </>
  );
}

function EditModal(props: IEditModal) {
  const { currentLimitFenceCard } = useAppSelector(
    (state: RootState) => state.limitFenceCards
  );
  const {onClose, isOpen, row} = props;

  const [localComment, setLocalComment] = useState(row?.original.comment ? row?.original.comment : "");

  useEffect(() => {
    setLocalComment(row?.original?.comment ? row?.original.comment : "");
  }, [row]);

  const dispatch = useAppDispatch();

  const {
    isOpen: isSuccessOpen,
    onOpen: onSuccessOpen,
    onClose: onSuccessClose,
  } = useDisclosure();

  const handleCommentModalClose = () => {
    if (row) {
      const index = row.index;
      if (index !== undefined && currentLimitFenceCard?.materials) {
        const updatedMaterials: INewLimitFenceCardMaterial[] = [...currentLimitFenceCard?.materials];
        updatedMaterials[index] = {
          ...updatedMaterials[index],
            comment: localComment ?? "",
        };

        const updatedLimitFenceCard = {...currentLimitFenceCard, materials: updatedMaterials};
        dispatch(changeCurrentLimitFenceCard(updatedLimitFenceCard));
      }

      onClose();
      onSuccessOpen();
    }
  };

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay backdropFilter="blur(3px)" />
        <ModalContent maxW="480px" gap="24px" px="24px" py="32px">
          <ModalHeader p={0}>
            <Image cursor="pointer" src={close} float="right" onClick={onClose} />
            <Flex direction="column" align="center" gap="24px" pb="8px">
              <Image src={MainLogo} boxSize="64px" alt="Main Logo" />
              <Title textAlign="center">Комментарий</Title>
            </Flex>
          </ModalHeader>
          <ModalBody p={0}>
            <Textarea h="360px" value={localComment} onChange={(e) => {setLocalComment(e.target.value)}}/>
          </ModalBody>
          <ModalFooter p={0}>
            <PrimaryButton
              w="full"
              fontSize="16px"
              onClick={handleCommentModalClose}
            >
              Сохранить
            </PrimaryButton>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <SuccessModal isOpen={isSuccessOpen} onClose={onSuccessClose} />
    </>
  );
}

function SuccessModal(props: Readonly<ChakraModalProps>) {
  const {isOpen, onClose} = props
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent maxW="480px" gap="24px" px="24px" py="32px">
        <ModalHeader p={0}>
          <Flex direction="column" align="center" gap="24px">
            <Image src={SuccessLogo} boxSize="100px" alt="Success Logo" />
            <BlockTitle>Комментарий успешно изменён</BlockTitle>
          </Flex>
        </ModalHeader>
        <ModalFooter p={0}>
          <PrimaryButton w="full" onClick={onClose}>
            Закрыть
          </PrimaryButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
