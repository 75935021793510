import { AxiosPromise } from "axios";
import { axiosInstance } from "../instance";
import {Files, LimitFenceCardsEndpoints} from "../endpoints";
import {
  ICreateLimitFenceCardRequest,
  IExportLzkRequest,
  ILimitFenceCardsResponse,
  INewCreateLimitFenceCardRequest,
  INewSingleLimitFenceCardMaterialsResponse,
  ISingleLimitFenceCardMaterialsResponse,
  ISingleLimitFenceCardResponse, IUpdateLimitFenceCardRequest,
} from "./types";
import { IContractsResponse } from "../contracts/types";

export const getLimitFenceCards = (): AxiosPromise<ILimitFenceCardsResponse> =>
  axiosInstance.get(LimitFenceCardsEndpoints.LIMIT_FENCE_CARDS);

export const getLimitFenceCardsByProject = (
  id: number
): AxiosPromise<ILimitFenceCardsResponse> =>
  axiosInstance.get(
    LimitFenceCardsEndpoints.LIMIT_FENCE_CARDS_BY_PROJECT + `/${id}`
  );

export const getLimitFenceCardsByContract = (
  id: number
): AxiosPromise<ILimitFenceCardsResponse> =>
  axiosInstance.get(
    LimitFenceCardsEndpoints.LIMIT_FENCE_CARDS_BY_CONTRACT + `/${id}`
  );

export const getLimitFenceCardContracts = (): AxiosPromise<IContractsResponse> =>
  axiosInstance.get(
    LimitFenceCardsEndpoints.SINGLE_LIMIT_FENCE_CARD + `/contracts`
  );

export const getSingleLimitFenceCard = (
  id: number
): AxiosPromise<ISingleLimitFenceCardResponse> =>
  axiosInstance.get(
    LimitFenceCardsEndpoints.SINGLE_LIMIT_FENCE_CARD + `/${id}`
  );

export const getSingleLimitFenceCardMaterials = (
  id: number,
  page: number
): AxiosPromise<ISingleLimitFenceCardMaterialsResponse> =>
  axiosInstance.get(
    LimitFenceCardsEndpoints.SINGLE_LIMIT_FENCE_CARD +
      `/${id}/materials?page=${page}`
  );

export const getNewSingleLimitFenceCardMaterials = (
  id: number,
): AxiosPromise<INewSingleLimitFenceCardMaterialsResponse> =>
  axiosInstance.get(
    LimitFenceCardsEndpoints.SINGLE_LIMIT_FENCE_CARD +
      `/${id}/materials`
  );

export const createLimitFenceCard = (
  params: INewCreateLimitFenceCardRequest
): AxiosPromise<ISingleLimitFenceCardResponse> =>
  axiosInstance.post(LimitFenceCardsEndpoints.CREATE_LIMIT_FENCE_CARD, params);

export const approveLimitFenceCard = (id: number): AxiosPromise<ISingleLimitFenceCardResponse> =>
  axiosInstance.post(LimitFenceCardsEndpoints.APPROVE_LIMIT_FENCE_CARD, {id});

export const updateLimitFenceCard = (params: IUpdateLimitFenceCardRequest): AxiosPromise<ISingleLimitFenceCardResponse> =>
  axiosInstance.post(LimitFenceCardsEndpoints.UPDATE_LIMIT_FENCE_CARD, params);

export const exportMaterials = (
  params: IExportLzkRequest
): AxiosPromise<Blob> =>
  axiosInstance.post(
    LimitFenceCardsEndpoints.SINGLE_LIMIT_FENCE_CARD + `/export`,
    params,
    { responseType: "blob" }
  );

