import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api";
import { createAsyncThunkInstance } from "../createAsyncThunkInstance";
import { isError } from "..";
import {
  ICreateDirectionLetterRequest,
  IDirectionLetter,
  IDirectionLetterCheckingAccounts,
  IDirectionLetterCheckingAccountsResponse,
  IDirectionLettersResponse,
  IDirectionLetterSuppliers,
  IDirectionLetterSuppliersResponse,
  IPrintFormResponse,
  ISingleDirectionLetterResponse,
  IUpdateDirectionLetterRequest,
} from "../../api/directionLetters/types";
import { ErrorType, ICommonInitialState } from "../../shared/types/storeTypes";
import { IFilesAttachResponse } from "../../api/files/types";
import { AxiosError } from "axios";
import { attachFiles } from "../../api/files";
import { DirectionLettersEndpoints, Files } from "../../api/endpoints";
import { createDirectionLetter, getPrintForm } from "../../api/directionLetters";

interface IDirectionLettesInittialState extends ICommonInitialState {
  directionLetters: IDirectionLetter[];
  LFCDirectionLetters: IDirectionLetter[];
  contractDirectionLetters: IDirectionLetter[];
  currentDirectionLetter: IDirectionLetter | null;
  directionLetterSuppliers: IDirectionLetterSuppliers[];
  currentSupplier: IDirectionLetterSuppliers | null;
  checkingAccounts: IDirectionLetterCheckingAccounts[]
  currentCheckingAccount: IDirectionLetterCheckingAccounts | null;
}

const initialState: IDirectionLettesInittialState = {
  directionLetters: [],
  LFCDirectionLetters: [],
  contractDirectionLetters: [],
  directionLetterSuppliers: [],
  checkingAccounts: [],
  currentDirectionLetter: null,
  currentSupplier: null,
  currentCheckingAccount: null,
  isLoading: false,
  error: null,
};

export const fetchDirectionLetters = createAsyncThunkInstance<
  IDirectionLettersResponse,
  undefined
>("fetchDirectionLetters", api.directionLetters.getDirectionLetters);

export const fetchDirectionLetterSuppliers = createAsyncThunkInstance<
  IDirectionLetterSuppliersResponse,
  number
>("fetchDirectionLetterSuppliers", api.directionLetters.getDirectionLetterSuppliers);

export const fetchDirectionLettersByLFC = createAsyncThunkInstance<
  IDirectionLettersResponse,
  number
>(
  "fetchDirectionLettersByLFC",
  api.directionLetters.getDirectionLettersByLimitFenceCard
);

export const fetchDirectionLettersByContract = createAsyncThunkInstance<
  IDirectionLettersResponse,
  number
>(
  "fetchDirectionLettersByContract",
  api.directionLetters.getDirectionLettersByContract
);

export const fetchSingleDirectionLetter = createAsyncThunkInstance<
  ISingleDirectionLetterResponse,
  string
>("fetchSingleDirectionLetter", api.directionLetters.getSingleDirectionLetter);

export const fetchSupplierCheckingAccounts = createAsyncThunkInstance<
  IDirectionLetterCheckingAccountsResponse,
  string
>("fetchSupplierCheckingAccounts", api.directionLetters.getSupplierCheckingAccounts);


export const fetchPrintForm = createAsyncThunk<
  IPrintFormResponse,
  { id: string; params: { type: string } },
  { rejectValue: AxiosError }
>(
  "directionLetters/fetchPrintForm",
  async ({ id, params }, { rejectWithValue }) => {
    try {
      const { data } = await getPrintForm(id, params);
      return data;
    } catch (error: unknown) {
      return rejectWithValue(error as AxiosError);
    }
  }
);

export const attachFilesToDirrectionLetter = createAsyncThunk<
  IFilesAttachResponse,
  { id: string; files: File[] },
  { rejectValue: AxiosError }
>(
  "directionLetters/attachFilesToDirrectionLetter",
  async ({ id, files }, { rejectWithValue }) => {
    try {
      const { data } = await attachFiles(DirectionLettersEndpoints.DIRECTION_LETTER + `/${id}` + Files.ATTACH_FILES, files);
      return data;
    } catch (error: unknown) {
      return rejectWithValue(error as AxiosError);
    }
  }
);

export const addDirectionLetter = createAsyncThunk<
  undefined,
  ICreateDirectionLetterRequest,
  { rejectValue: AxiosError }
>(
  "directionLetters/addDirectionLetter",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await createDirectionLetter(params);

      return data;
    } catch (error: unknown) {
      return rejectWithValue(error as AxiosError);
    }
  }
);

export const updateDirectionLetter = createAsyncThunk<
  undefined,
  { id: string; params: IUpdateDirectionLetterRequest },
  { rejectValue: AxiosError }
>(
  "directionLetters/updateDirectionLetter",
  async ({ id, params }, { rejectWithValue }) => {
    try {
      const { data } = await api.directionLetters.updateDirectionLetter(
        id,
        params
      );

      return data;
    } catch (error: unknown) {
      return rejectWithValue(error as AxiosError);
    }
  }
);

const directionLettersSlice = createSlice({
  name: "directionLetters",
  initialState,
  reducers: {
    resetCurrentDirectionLetter(state) {
      state.currentDirectionLetter = null;
    },
    resetCurrentSupplier(state) {
      state.currentSupplier = null;
    },
    resetCheckingAccount(state) {
      state.currentCheckingAccount = null;
    },
    resetDirectionLetterSuppliers(state) {
      state.directionLetterSuppliers = []
    },
    changeCurrentSupplier(state, action) {
      state.currentSupplier = action.payload;
    },
    changeCurrentCheckingAccount(state, action) {
      state.currentCheckingAccount = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDirectionLetters.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchDirectionLetters.fulfilled, (state, action) => {
        state.isLoading = false;
        state.directionLetters = action.payload.data;
      })

      .addCase(fetchDirectionLetterSuppliers.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchDirectionLetterSuppliers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.directionLetterSuppliers = action.payload.data;
      })

      .addCase(fetchDirectionLettersByLFC.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchDirectionLettersByLFC.fulfilled, (state, action) => {
        state.isLoading = false;
        state.LFCDirectionLetters = action.payload.data;
      })

      .addCase(fetchDirectionLettersByContract.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchDirectionLettersByContract.fulfilled, (state, action) => {
        state.isLoading = false;
        state.contractDirectionLetters = action.payload.data;
      })

      .addCase(fetchSingleDirectionLetter.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSingleDirectionLetter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentDirectionLetter = action.payload.data;
      })

      .addCase(fetchSupplierCheckingAccounts.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSupplierCheckingAccounts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.checkingAccounts = action.payload.data;
      })

      .addMatcher(isError, (state, action: PayloadAction<ErrorType>) => {
        state.error = action.payload.response?.data.message;
      });
  },
});

export const directionLettersReducer = directionLettersSlice.reducer;
export const { resetCurrentDirectionLetter, resetDirectionLetterSuppliers, changeCurrentSupplier, changeCurrentCheckingAccount, resetCurrentSupplier, resetCheckingAccount } = directionLettersSlice.actions;
