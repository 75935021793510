import { INewCreateLimitFenceCardRequest, INewLimitFenceCardMaterial } from "../api/limitFenceCards/types";
import { v4 as uuidv4 } from 'uuid';

export const formatCreateLzkRequest = (data: any) => {
  const formattedData: INewCreateLimitFenceCardRequest = {
    contract_id: data.contract_id || "",
    period_from: data.period_from || "",
    period_to: data.period_to || "",
    comment: data.comment || "",
    create_rp: data.create_rp,
    purchase_request: data.purchase_request,
    supplier_guid: data.supplier_guid || "",
    supplier_account: data.supplier_account || "",
    supplier_account_date_from: data.supplier_account_date_from || "",
    materials: data.materials.map((material: INewLimitFenceCardMaterial) => ({
      guid: uuidv4(),
      material_guid: material.material_guid ?? "",
      custom_material_name: material.custom_material_name ?? "",
      custom_unit_of_measurement: material.custom_unit_of_measurement ?? "",
      amount: material.amount ? Number(material.amount) : "",
      period: material.period ?? "",
      comment: material.comment ?? "",
    })),
  };

  return formattedData;
};

export const formatCompletedMaterials = (array: any) => {
  return array.filter((material: INewLimitFenceCardMaterial) => {
   return (Number(material.amount) > 0 && material.period && material.period !== ""); 
  });
 };