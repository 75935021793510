import { useEffect, useMemo, useState } from "react";
import { CellContext, createColumnHelper } from "@tanstack/react-table";
import { Flex, Icon, Text } from "@chakra-ui/react";
import { Table } from "../lib/table/Table";
import { FilterHeader } from "../Filter/FilterHeader";
import { filterFunction } from "../../helpers/filterFunction";
import { ReactComponent as EditPen } from "../../images/svg/edit/edit-pen.svg";
import { ReactComponent as Link } from "../../images/svg/link/link.svg";
import { ISigning } from "../../api/signing/types";
import { fetchSigningList } from "../../store/slices/signing";
import { RootState, useAppSelector } from "../../store";
import { ContractCell } from "../ContractCell/ContractCell";
import { SuccessCopyModal } from "../Modal/SuccessCopyModal";
import { formatAmount } from "../../helpers/formatAmount";


const columnHelper = createColumnHelper<ISigning>();

const DownloadCell = (info: CellContext<ISigning, string>) => {
    const [isCopied, setIsCopied] = useState<boolean>(false)

    return (
        <Flex gap="16px">
            <Icon
                data-tooltip-id="tooltip"
                data-tooltip-content="Перейти в ДИАДОК"
                as={EditPen}
                cursor="pointer"
                fill="#2AB6A5"
                sx={{
                    "&:hover": {
                        opacity: 0.6
                    }
                }}
                onClick={() => {
                    window.open(`${info.row.original.diadoc_sign_url}`)
                }}
            />
            <Icon
                data-tooltip-id="tooltip"
                data-tooltip-content="Скопировать сслыку"
                as={Link}
                cursor="pointer"
                fill="#2AB6A5"
                sx={{
                    "&:hover": {
                        opacity: 0.6
                    }
                }}
                onClick={() => { 
                    navigator.clipboard.writeText(`${info.row.original.diadoc_sign_url}`) 
                    setIsCopied(true)
                    setTimeout(() => {
                        setIsCopied(false)
                    }, 750)
                }}
            />
            <SuccessCopyModal openModal={isCopied} />
        </Flex>
    )
};

const DeadlineHeader = () => (
    <Text textAlign="left">Дней на <br /> подписании</Text>
);

const DiadocHeader = () => (
    <Text textAlign="left">Документ <br /> в Диадок</Text>
);

const TypeCell = (info: CellContext<ISigning, string>) =>
(
    <Text
        fontSize="14px"
        lineHeight="14px"
        py={2}
        px={3}
        color="#343B4C"
        bg="#FEE7AC"
        borderRadius="6px"
        display="inline-flex"
    >
        {info.renderValue()}
    </Text>
)


export function SigningTable() {

    const { signingList } = useAppSelector(
        (state: RootState) => state.signing
    );

    const columns = useMemo(
        () => [
            columnHelper.accessor("days_to_sign", {
                header: DeadlineHeader,
                enableSorting: true

            }),
            columnHelper.accessor("document", {
                header: "Документ",
                enableColumnFilter: true
            }),
            columnHelper.accessor("type", {
                header: (info) => FilterHeader(info.column, info.table, "Тип"),
                cell: TypeCell,
                enableColumnFilter: true,
                filterFn: ({ original: { type } }, id, filterValues) =>
                    filterFunction({ value: type, filterValues }),
                meta: {
                    filterSelect: true
                },
            }),
            columnHelper.accessor("contract_name", {
                header: "Договор",
                cell: (info) => ContractCell(info, String(info.cell.row.original.contract_id)),
            }),
            columnHelper.accessor("project_name", {
                header: "Проект",
            }),
            columnHelper.accessor("amount", {
                header: "Сумма",
                cell: (info) => formatAmount(info.renderValue())
            }),
            columnHelper.accessor("diadoc_name", {
                header: DiadocHeader,
                enableColumnFilter: true
            }),
            {
                header: "",
                id: "download",
                cell: DownloadCell,
            },
        ],
        []
    );

    return (
        <Table minHeight="300px" data={signingList} fetchFunction={fetchSigningList} columns={columns} dblClickDisabled={true} />
    );
}