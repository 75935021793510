import {
  ColumnDef,
  createColumnHelper,
} from "@tanstack/react-table";
import { IRemarksConstruction } from "../../api/remarks/types";
import { useEffect, useMemo } from "react";
import { Table } from "../lib/table/Table";
import dayjs from "dayjs";
import { FilterHeader } from "../Filter/FilterHeader";
import { StatusCellTable } from "../StatusCellTable/StatusCellTable";
import { filterFunction } from "../../helpers/filterFunction";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { fetchRemarksConstructionList, resetCurrentRemarkConstruction } from "../../store/slices/remarks";
import { ContractCell } from "../ContractCell/ContractCell";
import { Box } from "@chakra-ui/react";
import { OnboardingByPermission } from "../OnboardingByPermission/OnboardingByPermission";
import { AccessRights } from "../../shared/mock/sidebarContent";

const columnHelper = createColumnHelper<IRemarksConstruction>();

export function RemarksConstructionTable() {
  const { remarksConstructionList, currentRemarkConstruction} = useAppSelector(
    (state: RootState) => state.remarks
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetCurrentRemarkConstruction());
    };
  }, []);

  const columns = useMemo<Array<ColumnDef<IRemarksConstruction, string>>>(
    () => [
      columnHelper.accessor("number", {
        header: "Номер",
      }),
      columnHelper.accessor("date", {
        header: "Дата",
        cell: (info) => dayjs(info.renderValue()).format("DD.MM.YYYY"),
        enableSorting: true,
      }),
      columnHelper.accessor("status", {
        header: (info) => FilterHeader(info.column, info.table, "Статус"),
        cell: (info) => StatusCellTable(info.renderValue(), "#AAE2DB"),
        enableColumnFilter: true,
        filterFn: ({ original: { status } }, id, filterValues) =>
          filterFunction({ value: status, filterValues }),
        meta: {
          filterSelect: true
        }
      }),
      columnHelper.accessor("contract_name", {
        header: "Договор",
        cell: (info) => ContractCell(info, String(info.cell.row.original.contract_id)),
      }),
      columnHelper.accessor("project_name", {
        header: "Проект",
      }),
    ],
    []
  );
  return(
    <>
    <Table fetchFunction={fetchRemarksConstructionList} 
    isGuid={true}
    data={remarksConstructionList} 
    columns={columns} 
    lastViewedDocument={currentRemarkConstruction?.guid}
    minHeight="300px"/>
    <Box>
      <OnboardingByPermission permission={AccessRights.ConstructionControlNote_RW} />
    </Box>
    </>
  )

}
