import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../api";
import { createAsyncThunkInstance } from "../createAsyncThunkInstance";
import { isError } from "..";
import {
  IAddCompensationActResponse,
  ISavingsCompensationAct,
  ISavingsCompensationActRequest,
  ISavingsCompensationActsResponse,
  ISingleSavingsCompensationActResponse,
  IUpdateCompensationActRequest,
} from "../../api/savingsCompensationActs/types";
import { ErrorType, ICommonInitialState } from "../../shared/types/storeTypes";
import { AxiosError } from "axios";
import {createCompensationAct, updateCompensationAct} from "../../api/savingsCompensationActs";
import {IFilesAttachResponse} from "../../api/files/types";
import { attachFiles } from "../../api/files";
import { Files, SavingsCompensationActsEndpoints } from "../../api/endpoints";
import { IContract, IContractsResponse } from "../../api/contracts/types";

interface ISavingsCompensationActsInitialState extends ICommonInitialState {
  savingsCompensationActs: ISavingsCompensationAct[];
  contractSavingsCompensationActs: ISavingsCompensationAct[];
  projectSavingsCompensationActs: ISavingsCompensationAct[];
  currCompAct: ISavingsCompensationAct | null;
  contractsSavingsCompensationAct: IContract[];
}

const initialState: ISavingsCompensationActsInitialState = {
  savingsCompensationActs: [],
  contractSavingsCompensationActs: [],
  projectSavingsCompensationActs: [],
  currCompAct: null,
  contractsSavingsCompensationAct: [],
  isLoading: false,
  error: null,
};

export const fetchSavingsCompensationActs = createAsyncThunkInstance<
  ISavingsCompensationActsResponse,
  undefined
>(
  "savingsCompensationActs/fetchSavingsCompensationActs",
  api.savingsCompensationActs.getSavingsCompensationActs
);

export const fetchSavingsCompensationActsByProject = createAsyncThunkInstance<
  ISavingsCompensationActsResponse,
  number
>(
  "savingsCompensationActs/fetchSavingsCompensationActsByProject",
  api.savingsCompensationActs.getSavingsCompensationActsByProject
);

export const fetchSavingsCompensationActsByContract = createAsyncThunkInstance<
  ISavingsCompensationActsResponse,
  undefined
>(
  "savingsCompensationActs/fetchSavingsCompensationActsByContract",
  api.savingsCompensationActs.getSavingsCompensationActs
);

export const fetchSingleSavingsCompensationAct = createAsyncThunkInstance<
  ISingleSavingsCompensationActResponse,
  number
>(
  "savingsCompensationActs/fetchSingleSavingsCompensationAct",
  api.savingsCompensationActs.getSingleSavingsCompensationAct
);

export const fetchSavingCompensationActContracts = createAsyncThunkInstance<
  IContractsResponse,
  undefined
>(
  "savingsCompensationActs/fetchSavingCompensationActContracts",
  api.savingsCompensationActs.getCompensationActContracts
);


export const addSingleSavingsCompensationAct = createAsyncThunk<
  IAddCompensationActResponse,
  ISavingsCompensationActRequest,
  { rejectValue: AxiosError }
>(
  "workCompletedActs/addWorkCompletedAct",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await createCompensationAct(params);

      return data;
    } catch (error: unknown) {
      return rejectWithValue(error as AxiosError);
    }
  }
);

export const updateSingleSavingsCompensationAct = createAsyncThunk<
  undefined,
  { params: IUpdateCompensationActRequest; id: number },
  { rejectValue: AxiosError }
>(
  "savingsCompensationActs/updateSingleSavingsCompensationAct",
  async ({params, id}, { rejectWithValue }) => {
    try {
      await updateCompensationAct(id,params);
    } catch (error: unknown) {
      return rejectWithValue(error as AxiosError);
    }
  }
);

export const attachFilesToSingleSavingsCompensationAct = createAsyncThunk<
  IFilesAttachResponse,
  { id: number; files: File[] },
  { rejectValue: AxiosError }
>(
  "limitFenceCards/attachFilesToSingleSavingsCompensationAct",
  async ({ id, files }, { rejectWithValue }) => {
    try {
      const { data } = await attachFiles(SavingsCompensationActsEndpoints.SINGLE_SAVINGS_COMPENSATION_ACT + `/${id}` + Files.ATTACH_FILES, files);
      return data;
    } catch (error: unknown) {
      return rejectWithValue(error as AxiosError);
    }
  }
);


const savingsCompensationActsSlice = createSlice({
  name: "savingsCompensationActs",
  initialState,
  reducers: {
    resetSavingsCompensationActsByProject(state) {
      state.projectSavingsCompensationActs = [];
    },
    resetSavingsCompensationActsByContract(state) {
      state.contractSavingsCompensationActs = [];
    },
    resetCurentSavingsCompensationAct(state) {
      state.currCompAct = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSavingsCompensationActs.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSavingsCompensationActs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.savingsCompensationActs = action.payload.data;
      })

      .addCase(fetchSavingsCompensationActsByProject.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        fetchSavingsCompensationActsByProject.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.projectSavingsCompensationActs = action.payload.data;
        }
      )

      .addCase(fetchSavingsCompensationActsByContract.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        fetchSavingsCompensationActsByContract.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.contractSavingsCompensationActs = action.payload.data;
        }
      )

      .addCase(fetchSingleSavingsCompensationAct.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSingleSavingsCompensationAct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currCompAct = action.payload.data;
      })

      .addCase(fetchSavingCompensationActContracts.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSavingCompensationActContracts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.contractsSavingsCompensationAct = action.payload.data;
      })

      .addMatcher(isError, (state, action: PayloadAction<ErrorType>) => {
        state.isLoading = false;
        state.error = action.payload.response?.data.message;
      });
  },
});

export const savingsCompensationActsReducer =
  savingsCompensationActsSlice.reducer;

export const {
  resetSavingsCompensationActsByProject,
  resetSavingsCompensationActsByContract,
  resetCurentSavingsCompensationAct
} = savingsCompensationActsSlice.actions;
