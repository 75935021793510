import { useMemo } from "react";
import {
  CellContext,
  ColumnDef,
  createColumnHelper,
} from "@tanstack/react-table";
import { Box, Center, Text } from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { FilterHeader } from "../Filter/FilterHeader";
import { Table } from "../lib/table/Table";
import { StatusCellTable } from "../StatusCellTable/StatusCellTable";
import { RootState, useAppSelector } from "../../store";
import { fetchDirectionLetters } from "../../store/slices/directionLetters";
import { IDirectionLetter } from "../../api/directionLetters/types";
import { formatAmount } from "../../helpers/formatAmount";
import { FilterHeaderMarker } from "../Filter/FilterHeaderMarker";
import { filterFunction } from "../../helpers/filterFunction";
import { OnboardingByPermission } from "../OnboardingByPermission/OnboardingByPermission";
import { AccessRights } from "../../shared/mock/sidebarContent";

const columnHelper = createColumnHelper<IDirectionLetter>();

const PaidCell = ({
  row: { original },
}: CellContext<IDirectionLetter, string>) => {
  return (
    original.paid_status && (
      <Center>
        <CheckCircleIcon color="#2AB6A5" />
      </Center>
    )
  );
};

  const AmountCell = ({ row: { original } }: CellContext<IDirectionLetter, string>) => {
    const amount = formatAmount((original.amount))

    return <Text style={{ whiteSpace: "nowrap" }}>{amount}</Text>
  }
    

const LineClampCell = (info: CellContext<IDirectionLetter, string>) => (
  <Text noOfLines={2}>{info.renderValue()}</Text>
);

export function OrderLettersTable() {
  const { directionLetters, currentDirectionLetter } = useAppSelector((state: RootState) => state.directionLetters);

  const columns = useMemo<Array<ColumnDef<IDirectionLetter, string>>>(
    () => [
      columnHelper.accessor("status", {
        header: "Статус",
        cell: (info) => StatusCellTable(info.renderValue()),
      }),
      columnHelper.accessor("paid_status", {
        header: (info) =>
          FilterHeaderMarker(
            "Оплата",
            info.column,
            "Оплачено",
            "Не оплачено"
          ),
        cell: PaidCell,
      }),
      columnHelper.accessor("created_date", {
        header: "Дата создания",
        enableSorting: true,
      }),
      columnHelper.accessor("number", {
        header: "Номер",
      }),
      columnHelper.accessor("a101_org_name", {
        header: "Организация",
      }),
      columnHelper.accessor("supplier_name", {
        header: (info) => FilterHeader(info.column, info.table, "Поставщик"),
        enableColumnFilter: true,
        meta: {
          filterSelect: true
        },
        filterFn: ({ original: { supplier_name } }, id, filterValues) =>
          filterFunction({ value: supplier_name, filterValues })
      }),

      columnHelper.accessor("contract_name", {
        header: "Договор",
        enableColumnFilter: true,
      }),

      columnHelper.accessor("amount", {
        header: "Сумма",
        cell: AmountCell
      }),
      columnHelper.accessor("limit_card_number", {
        header: "ЛЗК",
        cell: LineClampCell,
        enableColumnFilter: true,
      }),
    ],
    []
  );

  return (
    <>
      <Table
        isGuid={true}
        data={directionLetters}
        columns={columns}
        fetchFunction={fetchDirectionLetters}
        lastViewedDocument={currentDirectionLetter?.guid}
        minHeight="360px"
      />
      <Box>
        <OnboardingByPermission permission={AccessRights.Purchase_RW} />
      </Box>
    </>
  );
}
