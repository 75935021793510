import { AxiosResponse } from "axios";
import { getRequestedFile } from "../api/files";

export function downloadRequestedFile(
  fileGuid: string,
  setIsSpinnerRuning: (isOpen: boolean) => void
) {
  setIsSpinnerRuning(true);
  getRequestedFile(fileGuid).then((res: AxiosResponse<Blob>) => {
    if (Math.floor(res.status / 100) === 2) {
      if (res.data?.size) {
        const contentDisposition = res.headers["content-disposition"];
        let filename = "unknown";

        if (contentDisposition) {
          const filenameMatch = contentDisposition.match(/filename=([^;]+)/);
          filename = filenameMatch[1];
        }

        const mimeType = res.data.type;
        const extension = mimeType.split("/")[1];

        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/octet-stream" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${filename}.${extension}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setIsSpinnerRuning(false);
      } else {
        setTimeout(
          () => downloadRequestedFile(fileGuid, setIsSpinnerRuning),
          2000
        );
      }
    } else {
      setIsSpinnerRuning(false);
    }
  });
}
