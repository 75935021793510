import { IDetail } from "../api/estimates/types";
import { IWorkCompletedActDetail } from "../api/workCompletedActs/types";

export const estimateDetailsWithQuantity = (
  estimatesDetails: IDetail[] | undefined,
  actDetails: IWorkCompletedActDetail[] | undefined
) => {
  if (!estimatesDetails || !actDetails) {
    return estimatesDetails;
  }

  const matchingActDetails = actDetails.reduce((acc, actDetail) => {
    acc[`${actDetail.construction_object_guid}-${actDetail.type_of_work_guid}`] = actDetail;
    return acc;
  }, {} as Record<string, IWorkCompletedActDetail>);

  const addAmountToParents = (estimate: IDetail, amount: number, updatedEstimates: IDetail[]): void => {
    const parentIndex = updatedEstimates.findIndex(parent => parent.id === estimate.parent_id);

    if (parentIndex !== -1) {
      updatedEstimates[parentIndex] = {
        ...updatedEstimates[parentIndex],
        Theamount: String(Number(updatedEstimates[parentIndex].Theamount) + amount),
        Total: String(Number(updatedEstimates[parentIndex].Total) + amount)
      };
      addAmountToParents(updatedEstimates[parentIndex], amount, updatedEstimates);
    }
  };

  const updatedEstimates = [...estimatesDetails];

  updatedEstimates.forEach((estimate, index) => {
    const matchingActDetail = matchingActDetails[`${estimate.ConstructionObjectID}-${estimate.TypeofWorkID}`];

    if (matchingActDetail) {
      const quantity =
        matchingActDetail.quantity > estimate.Thenumberofstops
          ? estimate.Thenumberofstops
          : matchingActDetail.quantity;
      const amount = Number(quantity) * Number(estimate.Price);
      const percentage = (amount / Number(estimate.AmountEstimate)) * 100;

      updatedEstimates[index] = {
        ...estimate,
        quantityinthedocument: quantity,
        Document_Amount: String(amount),
        Theamount: String(amount),
        Total: String(amount),
        PercentageOfCompletion: String(percentage)
      };

      addAmountToParents(updatedEstimates[index], amount, updatedEstimates);
    }
  });

  return updatedEstimates;
};
