import { useEffect, useMemo, useState } from "react";
import {
  CellContext,
  ColumnDef,
  createColumnHelper,
} from "@tanstack/react-table";
import { FilterHeader } from "../Filter/FilterHeader";
import { Table } from "../lib/table/Table";
import { Box, Flex } from "@chakra-ui/react";
import dayjs from "dayjs";
import { CheckIcon } from "@chakra-ui/icons";
import { StatusCellTable } from "../StatusCellTable/StatusCellTable";
import { OutlineButton, PrimaryButton } from "../lib/button/Button";
import { ReactComponent as Printer } from "../../images/svg/documents/print-red.svg";
import { FilterHeaderMarker } from "../Filter/FilterHeaderMarker";
import { ReactComponent as Warning } from "../../images/svg/warning/warning.svg";
import { ReactComponent as Reverse } from "../../images/svg/reverse/reverse.svg";
import { filterFunction } from "../../helpers/filterFunction";
import { IRemarksTransmission } from "../../api/remarks/types";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { acceptRemarks, fetchPrintFormRemarks, fetchRemarksTransmissionList, fixedRemarks, resetCurrentRemarkTransmission } from "../../store/slices/remarks";
import { reverseDate } from "../../helpers/reverseDate";
import { OnboardingByPermission } from "../OnboardingByPermission/OnboardingByPermission";
import { AccessRights } from "../../shared/mock/sidebarContent";
import { useUniversalDownloadFile } from "../../helpers/useUniversalDownloadFile";
import { FullScreenWaitingModal } from "../Modal/FullScreenWaitingModal";

const columnHelper = createColumnHelper<IRemarksTransmission>();

const WarningCell = (info: CellContext<IRemarksTransmission, string>) => {
  return info.row.original.emergency && <CheckIcon color="#8089A2" />;
};

const ReverseCell = (info: CellContext<IRemarksTransmission, string>) => {
  return info.row.original.repeated && <CheckIcon color="#8089A2" />;
};

export function RemarksTransmissionTable() {
  const [disabledButton, setDisabledButton] = useState(false);
  const [selectedItems, setSelectedItems] = useState<IRemarksTransmission[]>(
    []
  );
  const [isDisabledAccept, setIsDisabledAccept] = useState<boolean>(true)
  const [isDisabledFixed, setIsDisabledFixed] = useState<boolean>(true)
  const [isDisabledPrintForm, setIsDisabledPrintForm] = useState<boolean>(true)
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [isSpinnerRuning, setIsSpinnerRuning] = useState<boolean>(false)

  const { remarksTransmissionList, currentRemarkTransmission } = useAppSelector(
    (state: RootState) => state.remarks
  );

  const { downloadFile } = useUniversalDownloadFile();

  const hasSelectedItems = selectedItems.length > 0;

  const handleSelectAllChange = () => {
    const newSelectAllChecked = !selectAllChecked;
    setSelectAllChecked(newSelectAllChecked);

    setSelectedItems(selectedItems.length === remarksTransmissionList.length || !newSelectAllChecked
      ? [] : [...remarksTransmissionList]);
  };

  useEffect(() => {
    setIsDisabledAccept(selectedItems.length === 0 ? true : false)
    setIsDisabledFixed(selectedItems.length === 0 ? true : false)
    setIsDisabledPrintForm(selectedItems.length === 0 ? true : false)
    selectedItems.forEach((el:IRemarksTransmission) => {
      setIsDisabledAccept((el.status !== "Передано подрядчику") ? true : false)
      setIsDisabledFixed((el.status !== "Принято подрядчиком") ? true : false)
    })
  }, [selectedItems])

  const handleAcceptRemarks = () => {
    let remarksList: string[] = [];
    selectedItems.forEach((el: IRemarksTransmission) => {
      remarksList.push(el.guid)
    })
    dispatch(acceptRemarks({ params: { ids: remarksList } }))
      .then(() => {
        setSelectedItems([])
        dispatch(fetchRemarksTransmissionList())
      })
  }

  const handleFixedRemarks = () => {
    let remarksList: string[] = [];
    selectedItems.forEach((el: IRemarksTransmission) => {
      remarksList.push(el.guid)
    })
    dispatch(fixedRemarks({ params: { ids: remarksList } }))
    .then(() => {
      setSelectedItems([])
      dispatch(fetchRemarksTransmissionList())
    })
  }

  const handleGetPrintForm = (type: string) => {
    let remarksList: string[] = [];
    selectedItems.forEach((el: IRemarksTransmission) => {
      remarksList.push(el.guid)
    })
    downloadFile(undefined, setIsSpinnerRuning, fetchPrintFormRemarks, {ids: remarksList, type: type})
  }


  const CheckboxHeader = ({
    onSelectAllChange,
    selectAllChecked,
    hasSelectedItems,
  }: {
    onSelectAllChange: () => void;
    selectAllChecked: boolean;
    hasSelectedItems: boolean;
  }) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          type="checkbox"
          onChange={onSelectAllChange}
          checked={selectAllChecked}
          style={{
            width: "20px",
            height: "20px",
            accentColor: "teal",
            cursor: "pointer",
            appearance: "none",
            borderRadius: "3px",
            backgroundColor: hasSelectedItems ? "teal" : "white",
            border: `1px solid ${hasSelectedItems ? "teal" : "#343b4c"}`,
          }}
        />
        {hasSelectedItems && (
          <div
            style={{
              width: "10px",
              height: "2px",
              backgroundColor: "white",
              marginLeft: "-15px",
            }}
          />
        )}
      </div>
    );
  };

  const CheckboxCell = ({
    initialSelected,
    initialData,
    setSelectedItems,
  }: {
    initialSelected: boolean;
    initialData: IRemarksTransmission;
    setSelectedItems: React.Dispatch<
      React.SetStateAction<IRemarksTransmission[]>
    >;
  }) => {
    const [selected, setSelected] = useState(initialSelected);

    const handleCheckboxClick = () => {
      setSelected(!selected);

      setSelectedItems((prevItems) => {
        if (prevItems.some((item) => item.guid === initialData.guid)) {
          return prevItems.filter((item) => item.guid !== initialData.guid);
        } else {
          return [...prevItems, initialData];
        }
      });
    };

    return (
      <input
        type="checkbox"
        checked={selected}
        onChange={handleCheckboxClick}
        style={{
          width: "20px",
          height: "20px",
          accentColor: "teal",
          cursor: "pointer",
        }}
      />
    );
  };

  useEffect(() => {
    setDisabledButton(selectedItems.length === 0);
  }, [selectedItems]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetCurrentRemarkTransmission());
    };
  }, []);

  const columns = useMemo<Array<ColumnDef<IRemarksTransmission, string>>>(
    () => [
      columnHelper.accessor("checkbox", {
        header: () => (
          <CheckboxHeader
            onSelectAllChange={handleSelectAllChange}
            selectAllChecked={selectAllChecked}
            hasSelectedItems={hasSelectedItems}
          />
        ),
        enableColumnFilter: true,
        meta: {
          filterSelect: true,
        },
        cell: (info) => (
          <CheckboxCell
            initialSelected={selectedItems.some(
              (item) => item.guid === info.row.original.guid
            )}
            initialData={info.row.original}
            setSelectedItems={setSelectedItems}
          />
        ),
      }),
      columnHelper.accessor("emergency", {
        header: (info) =>
          FilterHeaderMarker(
            Warning,
            info.column,
            "Только аварийные",
            "Только не аварийные"
          ),
        cell: WarningCell,
      }),
      columnHelper.accessor("type", {
        header: (info) => FilterHeader(info.column, info.table, "Тип"),
        enableColumnFilter: true,
        filterFn: ({ original: { type } }, id, filterValues) =>
          filterFunction({ value: type, filterValues }),
        meta: {
          filterSelect: true
        }
      }),
      columnHelper.accessor("repeated", {
        header: (info) =>
          FilterHeaderMarker(
            Reverse,
            info.column,
            "Только повторные",
            "Только не повторные"
          ),
        cell: ReverseCell,
      }),
      columnHelper.accessor("date", {
        header: "Дата",
        cell: (info) => dayjs(reverseDate(info.renderValue())).format("DD.MM.YYYY"),
        enableSorting: true,
        sortingFn: (firstRow, secondRow) => {
          const firstDate = dayjs(reverseDate(firstRow.original.date));
          const secondDate = dayjs(reverseDate(secondRow.original.date));
          return firstDate.isBefore(secondDate) ? -1 : firstDate.isAfter(secondDate) ? 1 : 0;
        },
      }),
      columnHelper.accessor("status", {
        header: (info) => FilterHeader(info.column, info.table, "Статус"),
        cell: (info) => StatusCellTable(info.renderValue(), "#ED5E64", "#fff"),
        enableColumnFilter: true,
        filterFn: ({ original: { status } }, id, filterValues) =>
          filterFunction({ value: status, filterValues }),
        meta: {
          filterSelect: true
        }
      }),
      columnHelper.accessor("number", {
        header: "№ документа",
        enableSorting: true,
      }),
      columnHelper.accessor("construction_object", {
        header: (info) =>
          FilterHeader(info.column, info.table, "Объект строительства"),
        enableColumnFilter: true,
        filterFn: ({ original: { construction_object } }, id, filterValues) =>
          filterFunction({ value: construction_object, filterValues }),
        meta: {
          filterSelect: true
        }
      }),
      columnHelper.accessor("real_estate_object", {
        header: "Объект недвижимости",
        enableColumnFilter: true,
      }),
      columnHelper.accessor("text_of_the_remark", {
        header: "Текст замечания",
      }),
    ],
    [selectedItems, selectAllChecked, hasSelectedItems]
  );

  return (
    <>
      <Flex justify="space-between">
        <Flex gap="12px">
          <PrimaryButton isDisabled={isDisabledAccept} onClick={handleAcceptRemarks}>Принять</PrimaryButton>
          <OutlineButton isDisabled={isDisabledFixed} onClick={handleFixedRemarks}>Устранено</OutlineButton>
        </Flex>
        <Flex gap="12px">
          <OutlineButton isDisabled={isDisabledPrintForm} leftIcon={<Printer />} onClick={() => handleGetPrintForm("transfer_act")}>Акт передачи</OutlineButton>
          <OutlineButton isDisabled={isDisabledPrintForm} leftIcon={<Printer />} onClick={() => handleGetPrintForm("act_of_elimination_of_comments")}>
            Акт устранения замечаний
          </OutlineButton>
        </Flex>
      </Flex>
      <Table fetchFunction={fetchRemarksTransmissionList} isGuid={true} selectedItems={selectedItems} data={remarksTransmissionList} columns={columns} lastViewedDocument={currentRemarkTransmission?.guid} minHeight="300px" />
      <Box>
          <OnboardingByPermission permission={AccessRights.TransferNote_RW} />
      </Box>
      <FullScreenWaitingModal openModal={isSpinnerRuning} />
    </>
  );
}
