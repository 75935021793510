import { ChevronDownIcon, ChevronRightIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
    Accordion,
    AccordionButton,
    AccordionItem,
    Box,
    Flex,
    Input,
    Text,
    useDisclosure,
    useOutsideClick,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { IAccount } from "../../api/supplier/types";
import { useAppDispatch } from "../../store";
import { selectAccount } from "../../store/slices/supplier";
import { FormikProps } from "formik";

interface MultiselectMenuProps {
    documents: IAccount[];
    setFieldValue?: any
    index?: number | null;
}

export function SupplierDropdown(props: MultiselectMenuProps) {
    const { documents, setFieldValue, index } =
        props;
    const [selectedDocument, setSelectedDocument] = useState<IAccount | null>(null);

    const dispatch = useAppDispatch()

    const { isOpen, onToggle, onClose } = useDisclosure();
    const ref = useRef(null);
    useOutsideClick({ ref, handler: onClose });

    const [showInput, setShowInput] = useState<boolean>(false)

    const handleDocumentSelect = (document: any) => {
        setSelectedDocument(document);
        onClose();
    };

    useEffect(() => {
        dispatch(selectAccount(selectedDocument))
    }, [selectedDocument, dispatch]);

    useEffect(() => {
        if (index === undefined && documents.length > 0) {
            setSelectedDocument(documents[0]);
        } else if (documents.length > 0 && index !== null && index !== undefined) {
            setSelectedDocument(documents[index]);
        }
    }, [documents, index]);


    return (
        <Accordion
            backgroundColor="#FFFFFF"
            color="#5C657E"
            border="1px solid #EBEEF5"
            borderRadius="8px"
            position="relative"
            marginLeft="-1px"
            ref={ref}
        >
            <AccordionItem border={showInput ? "none" : "1px solid #EBEEF5"}>
                <h2>
                    {!showInput ? (<AccordionButton p={0} cursor="default" onClick={onToggle}>
                        <Flex
                            margin="10px 12px"
                            w="100%"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Box fontWeight={400} as="span" flex="1" textAlign="left">
                                <Text color="#343B4C">{selectedDocument ? `${selectedDocument.number} (${selectedDocument.bic})` : ""}</Text>
                            </Box>
                            {isOpen ? (
                                <ChevronUpIcon boxSize={6} />
                            ) : (
                                <ChevronDownIcon boxSize={6} />
                            )}
                        </Flex>
                    </AccordionButton>) : (
                        <Flex align="center" padding="0px 12px">
                            <Input 
                                sx={{
                                    border: "none",
                                    "&:focus-visible": {
                                        border: "none",
                                        outline: "none",
                                        boxShadow: "none"
                                        }
                                    }}
                                onChange={(e) => {
                                    setFieldValue("checking_account_number", e.target.value)
                                }}
                                />
                                <ChevronRightIcon color="#E8363D" sx={{
                                    "&:hover": {
                                        cursor: "pointer"
                                    }
                                }} onClick={() => {
                                    setSelectedDocument(null);
                                    onToggle();
                                    setShowInput(false);
                                    setFieldValue("checking_account_bic", "")
                                }} boxSize={6} />

                            </Flex>
                    )}
                </h2>
                {isOpen && (
                    <Box
                        position="absolute"
                        top="46px"
                        left="0"
                        width="100%"
                        bg="white"
                        zIndex="10"
                        overflowY="auto"
                        maxHeight="300px"
                        borderRadius="6px"
                        border="1px solid #E3E7EF"
                        padding="4px"
                        sx={{
                            boxShadow: "0px 8px 24px 0px #1F222A0D",
                        }}
                    >
                        <Flex >
                            {documents.length > 0 && (
                                <Flex w="100%" gap="2px" flexDirection="column">
                                    {documents.map((document) => (
                                        <Flex
                                            key={document.guid}
                                            w="100%"
                                            h="39px"
                                            paddingLeft="16px"
                                            paddingRight="16px"
                                            paddingBottom="2px"
                                            borderBottom="1px solid #E3E7EF"
                                            alignItems="center"
                                            cursor="pointer"
                                            _hover={{
                                                cursor: "pointer",
                                                backgroundColor: "#F0F3FA",
                                            }}
                                            onClick={() => handleDocumentSelect(document)}
                                        >
                                            <Text
                                                w="100%"
                                                color={
                                                    selectedDocument?.guid === document.guid
                                                        ? "#2AB6A5"
                                                        : "#343B4C"
                                                }
                                                fontSize="16px"
                                                noOfLines={1}
                                                lineHeight="16px"
                                            >
                                                {`${document.number} (${document.bic})`}
                                            </Text>
                                        </Flex>
                                    ))}
                                    <Flex
                                        w="100%"
                                        h="39px"
                                        paddingLeft="16px"
                                        paddingRight="16px"
                                        paddingBottom="2px"
                                        alignItems="center"
                                        cursor="pointer"
                                        _hover={{
                                            cursor: "pointer",
                                            backgroundColor: "#F0F3FA",
                                        }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setShowInput(true);
                                            setSelectedDocument(null);
                                            onClose();
                                            dispatch(selectAccount(null))
                                            setFieldValue("checking_account_guid", "")
                                            setFieldValue("checking_account_bic", "")
                                        }}
                                        sx={{
                                            "&:last-child": {
                                                borderBottom: "none"
                                            }
                                        }}
                                    >
                                        <Text
                                            w="100%"
                                            fontSize="16px"
                                            noOfLines={1}
                                            color="#E8363D"
                                            lineHeight="16px"
                                        >
                                            Добавить новый Р/С
                                        </Text>
                                    </Flex>
                                </Flex>
                            )}
                        </Flex>
                    </Box>
                )}
            </AccordionItem>
        </Accordion>
    );
}