import {Flex, Radio, RadioGroup, Stack, Text} from "@chakra-ui/react";
import {BlockTitle} from "../lib/title/Title";
import {DocumentsFields} from "../CreateActBasedOnEstimateComponents/DocumentsFields";
import {useEffect, useState} from "react";
import { checkPermission } from "../../helpers/checkPermission";
import { AccessRights } from "../../shared/mock/sidebarContent";
import { RootState, useAppSelector } from "../../store";
import { FormikProps } from "formik";
import { ICreateWorkCompletedActRequest } from "../../api/workCompletedActs/types";
interface SingleEstimateActPrintDataProps {
  formik?: FormikProps<ICreateWorkCompletedActRequest>;
}

export function SingleEstimateActPrintData({formik}: SingleEstimateActPrintDataProps) {
  const { permissions } = useAppSelector(
    (state: RootState) => state.auth.authData
  );
  const { currentWorkCompletedAct } = useAppSelector((state) => state.workCompletedActs);

  const [radioValue, setRadioValue] = useState<string>(currentWorkCompletedAct && currentWorkCompletedAct.has_advance_payment === true ? "2" : "1");
  const isDisabledByPermission = !checkPermission(AccessRights.WorkCompletedAct_RW, permissions) || (!!currentWorkCompletedAct && !currentWorkCompletedAct?.can_edit)
  useEffect(() => {
    currentWorkCompletedAct && setRadioValue(currentWorkCompletedAct.has_advance_payment === true ? "2" : "1")
  }, [currentWorkCompletedAct])

  return(
    <Flex direction="column" gap={2}>
      <BlockTitle>Аванасовые документы</BlockTitle>
      <RadioGroup size="lg" colorScheme="teal" value={radioValue} onChange={setRadioValue}>
        <Stack h="40px" spacing={5} direction="row" align="center">
          <Radio isDisabled={isDisabledByPermission} value="1" borderColor="#AAB0C1">
            <Text fontSize="16px">Это первый платеж</Text>
          </Radio>
          <Radio
            isDisabled={isDisabledByPermission}
            alignItems="center"
            alignSelf="center"
            value="2"
            borderColor="#AAB0C1"
          >
            <Text fontSize="16px">Это не первый платеж</Text>
          </Radio>
        </Stack>
      </RadioGroup>
      {radioValue === "2" && <DocumentsFields formik={formik} />}
    </Flex>
  )
}
