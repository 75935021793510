import * as accessActs from "./accessActs";
import * as auth from "./auth";
import * as contracts from "./contracts";
import * as directionLetters from "./directionLetters";
import * as estimates from "./estimates";
import * as invoices from "./invoices";
import * as limitFenceCards from "./limitFenceCards";
import * as payments from "./payments";
import * as savingsCompensationActs from "./savingsCompensationActs";
import * as workCompletedActs from "./workCompletedActs";
import * as files from "./files";
import * as additionalRequests from "./additionalRequests";
import * as report from "./reports";
import * as remarks from "./remarks";
import * as requirements from "./requirements";
import * as reconciliationStatement from "./reconcilitiaonStatement";
import * as signing from "./signing";
import * as settlements from "./settlements";
import * as mainInfo from "./mainInfo";
import * as tooltips from "./tooltips";
import * as supplier from "./supplier";

const api = {
  accessActs,
  auth,
  contracts,
  directionLetters,
  estimates,
  invoices,
  limitFenceCards,
  payments,
  savingsCompensationActs,
  workCompletedActs,
  files,
  additionalRequests,
  report,
  remarks,
  requirements,
  reconciliationStatement,
  signing,
  settlements,
  mainInfo,
  tooltips,
  supplier
};

export default api;
