import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { sendSecurityService } from "../../../api/feedback";
import { ModalHeader } from "./ModalHeader/ModalHeader";
import { ModalFooter } from "./ModalFooter/ModalFooter";
import { OutlineButton, PrimaryButton } from "../../lib/button/Button";
import { DropFileInput } from "../../lib/input/drop-file-input/DropFileInput";
import { Modal, ModalProps } from "../Modal";
import { TextArea } from "../../lib/textarea/TextArea";
import { Select } from "../../lib/select/Select";
import { CheckboxInput } from "../../lib/input/CheckboxInput";
import { SuccessMessage } from "./ExecutorMessageModal";
import { ErrorMessage } from "../../ErrorMessage/ErrorMessage";
import { securityServiceSubjectOptions } from "../../../shared/mock/securityServiceSubjectOptions";
import {
  checkIfFilesAreTooBig,
  checkIfFilesAreCorrectType,
} from "../../../helpers/checkFiles";
import styles from ".././modal.module.scss";

type SecurityServiceModalProps = Omit<ModalProps, "children"> & {
  title: string;
  setSuccessSecuritySend: (arg: boolean) => void;
  confirmationLoss: boolean;
  setConfirmationLoss: (arg: boolean) => void;
};

export function SecurityServiceModal(props: SecurityServiceModalProps) {
  const { active, onClose, title, setSuccessSecuritySend, confirmationLoss, setConfirmationLoss } = props;
  const [securityServiceError, setSecurityServiceError] = useState<
    string | null
  >(null);
  const [success, setSuccess] = useState<boolean>(false);

  const handleCloseModal = () => {
    setSecurityServiceError(null);
    onClose();
    setSuccess(false);
  };

  const onSubmit = () => {
    let formData = new FormData();

    formik.values.attachments.forEach((attachment) => {
      formData.append("attachments[]", attachment);
    });
    formData.append("subject", formik.values.subject);
    formData.append("message", formik.values.message);
    formData.append(
      "feedback_checkbox",
      formik.values.feedback_checkbox.toString()
    );

    sendSecurityService(formData)
      .then(() => {
        setSuccess(true);
        setSuccessSecuritySend(true);
        formik.resetForm();
      })
      .catch((error) => {
        console.error(error);
        setSecurityServiceError(error.message);
      });
  };

  const formik = useFormik({
    initialValues: {
      subject: "",
      message: "",
      feedback_checkbox: false,
      attachments: [],
    },
    validationSchema: Yup.object({
      subject: Yup.string().required(),
      message: Yup.string().trim().required(),
      feedback_checkbox: Yup.boolean().required(),
      attachments: Yup.array()
        .nullable()
        .required()
        .test(
          "is-big-file",
          "Размер каждого из файлов не должен превышать 5 Мб",
          checkIfFilesAreTooBig
        )
        .test(
          "is-correct-file",
          "Формат файла не соответствует допустимому перечню",
          checkIfFilesAreCorrectType
        ),
    }),
    onSubmit,
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnMount: true,
  });

  const handleFileInputChange = (files: File[]) => {
    formik.setFieldValue("attachments", files);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue("feedback_checkbox", e.target.checked);
  };

  useEffect(() => {
    setConfirmationLoss(false);
    if (
      formik.values.message === "" &&
      formik.values.attachments.length === 0 &&
      formik.values.subject === "" && 
      formik.values.feedback_checkbox === false
    ) {
      setSuccessSecuritySend(true);
    } else {
      setSuccessSecuritySend(false);
    }
  }, [formik.values]);


  useEffect(() => {
    confirmationLoss && formik.resetForm();
  }, [confirmationLoss]);

  useEffect(() => {
    active && setSuccessSecuritySend(true);
  }, [active])

  return (
    <Modal active={active} onClose={handleCloseModal}>
      <div
        className={styles.modal_content}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <div className={styles.container}>
          <ModalHeader title={title} onClose={handleCloseModal} />

          <form className={styles.form} onSubmit={formik.handleSubmit}>
            {success ? (
              <SuccessMessage onClose={handleCloseModal} />
            ) : (
              <>
                <Select
                  label="Тема обращения"
                  name="subject"
                  value={formik.values.subject}
                  options={securityServiceSubjectOptions}
                  onChange={formik.handleChange}
                  error={formik.touched.subject && !!formik.errors.subject}
                />
                <div className={styles.modal_info}>
                  <TextArea
                    label="Текст сообщения"
                    id="message"
                    name="message"
                    placeholder="Введите текст сообщения"
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    error={formik.touched.message && !!formik.errors.message}
                  />
                  <CheckboxInput
                    label="Хочу получить обратную связь"
                    name="feedback_checkbox"
                    value={formik.values.feedback_checkbox}
                    onChange={handleCheckboxChange}
                  />
                  <DropFileInput
                    id="attachments"
                    label="Загрузка файлов"
                    text="Перетащите сюда файл(ы)"
                    name="attachments"
                    onChange={handleFileInputChange}
                    isLimitedFormat
                  />
                  {!!formik.errors.attachments && (
                    <ErrorMessage
                      message={formik.errors.attachments.toString()}
                    />
                  )}
                  {securityServiceError && (
                    <ErrorMessage message={securityServiceError} />
                  )}
                </div>
                <ModalFooter>
                  <OutlineButton onClick={onClose}>Отмена</OutlineButton>
                  <PrimaryButton type="submit">Отправить</PrimaryButton>
                </ModalFooter>
              </>
            )}
          </form>
        </div>
      </div>
    </Modal>
  );
}
