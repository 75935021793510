import { Flex, Menu } from "@chakra-ui/react";
import { ChevronDownIcon, DownloadIcon } from "@chakra-ui/icons";
import { PrimaryButton } from "../lib/button/Button";
import { ChakraMenuList } from "../lib/menu/ChakraMenuList";
import { BlockTitle } from "../lib/title/Title";
import { ActsTable } from "./ActsTable";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { OutlineMenuButton } from "../lib/menu/ChakraMenuButton";
import { changeEstimate, fetchSingleEstimate } from "../../store/slices/estimates";
import { estimateDetailsWithQuantity } from "../../helpers/estimateDetailsWithQuantity";
import { useLocation, useNavigate } from "react-router-dom";
import { ISingleEstimateResponse } from "../../api/estimates/types";
import { fetchPrintFormWorkAct } from "../../store/slices/workCompletedActs";
import { useUniversalDownloadFile } from "../../helpers/useUniversalDownloadFile";
import { useState } from "react";
import { FullScreenWaitingModal } from "../Modal/FullScreenWaitingModal";
import { checkPermission } from "../../helpers/checkPermission";
import { AccessRights } from "../../shared/mock/sidebarContent";


export function ActsBlock() {
  const { currentContract } = useAppSelector(
    (state: RootState) => state.contracts
  );
  const { permissions } = useAppSelector(
    (state: RootState) => state.auth.authData
  );
  const { downloadFile } = useUniversalDownloadFile();
  const [isSpinnerRuning, setIsSpinnerRuning] = useState<boolean>(false)

  const isRegistered = () => {return currentContract?.status == "Зарегистрирован"}

  const requestPrintForm = (type: string) => {
    downloadFile(currentWorkCompletedAct?.id, setIsSpinnerRuning, fetchPrintFormWorkAct, {type: type})
  }
  const actMenuList: { title: string, onClick?: () => void, isDisabled?: boolean }[] = [
    {
      title: "КС-2",
      onClick: () => requestPrintForm("ks_2"),
      isDisabled: !isRegistered()
    },
    {
      title: "Счёт",
      onClick: () => requestPrintForm("payment"),
      isDisabled: !isRegistered()
    },
    {
      title: "Счёт-фактуры",
      onClick: () => requestPrintForm("check"),
      isDisabled: !isRegistered()
    },
    {
      title: "Отгрузочные документы",
      onClick: () => requestPrintForm("shipping_documents"),
      isDisabled: !isRegistered()
    },
    {
      title: "Накопительная ведомость",
      onClick: () => requestPrintForm("accumulative_statement"),
      isDisabled: !isRegistered()
    },
    {
      title: "КС-3",
      onClick: () => requestPrintForm("ks_3"),
      isDisabled: !isRegistered()
    }
  ];
  const { currentWorkCompletedAct } = useAppSelector(
    (state) => state.workCompletedActs
  );
  const { currentEstimate } = useAppSelector((state) => state.estimates);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const dispatch = useAppDispatch();

  const handleCreateClick = async () => {
    currentWorkCompletedAct && dispatch(fetchSingleEstimate(currentWorkCompletedAct.estimate_id))
      .then((res) => {
        const payload = res.payload as ISingleEstimateResponse
        const resultDetails = estimateDetailsWithQuantity(payload.data.details, currentWorkCompletedAct?.details)
        const updatedEstimate = { ...payload.data, details: resultDetails };
        dispatch(changeEstimate(updatedEstimate))
        navigate(`${pathname}/create-act-based-act`)
      })
  }

  const isDisabledByAct = () => {return !currentWorkCompletedAct || (currentWorkCompletedAct.status !== "Отклонена" && currentWorkCompletedAct.status !== "Черновик ЛКП"); }

  return (
    <Flex direction="column" gap={4} maxH="full" h="full">
      <BlockTitle>Акты</BlockTitle>
      <Flex gap="12px" >
        <PrimaryButton isDisabled={isDisabledByAct() || currentContract?.is_archived || !checkPermission(AccessRights.WorkCompletedAct_RO, permissions)} onClick={handleCreateClick}>Создать на основании</PrimaryButton>
        <Menu>
          <OutlineMenuButton
            leftIcon={<DownloadIcon />}
            rightIcon={<ChevronDownIcon />}
            isDisabled={!currentWorkCompletedAct}
          >
            Скачать
          </OutlineMenuButton>
          <ChakraMenuList list={actMenuList} />
        </Menu>
      </Flex>
      <ActsTable />
      <FullScreenWaitingModal openModal={isSpinnerRuning} />
    </Flex>
  );
}
