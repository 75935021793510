import { Flex, Text } from "@chakra-ui/react";
import { BuildingObjectsTable } from "./BuildingObjectTable";
import { RootState, useAppSelector } from "../../store";
import { useEffect, useState } from "react";
import { formatAmount } from "../../helpers/formatAmount";
import { IWorkCompletedActDetail } from "../../api/workCompletedActs/types";

export function BuildingObjectsBlock() {

  const { detailsWorkCompletedAct } = useAppSelector((state: RootState) => state.workCompletedActs);

  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    const totalSum = detailsWorkCompletedAct.reduce((sum: number, detail: IWorkCompletedActDetail) => {
      const quantity = Number(detail.quantity) || 0;
      const price = Number(detail.price) || 0;
      return sum + quantity * price;
    }, 0);

    const fixedTotalSum = totalSum.toFixed(2)
    setTotal(Number(fixedTotalSum));
  }, [detailsWorkCompletedAct]);


  return (
    <Flex direction="column">
      <Text h="40px" textAlign="end" fontWeight={500}>
      ИТОГО: {formatAmount(total)}
      </Text>
      <BuildingObjectsTable />
    </Flex>
  );
}