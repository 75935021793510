import { AxiosResponse } from "axios";
import { exportEstimate } from "../api/estimates";
import { ICreateWorkCompletedActDetail, IWorkCompletedActDetail } from "../api/workCompletedActs/types";

export const downloadExportEstimate = (
  id: number,
  data: ICreateWorkCompletedActDetail[] | IWorkCompletedActDetail[] | [],
  setIsSpinnerRuning?: (arg: boolean) => void
) => {
  setIsSpinnerRuning && setIsSpinnerRuning(true);
  exportEstimate(id, data)
    .then((res: AxiosResponse<Blob>) => {
      const contentDisposition = res.headers["content-disposition"];
      let filename = "unknown";

      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename=([^;]+)/);
        filename = filenameMatch[1];
      }

      const url = window.URL.createObjectURL(
        new Blob([res.data], { type: "application/octet-stream" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${filename}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .finally(() => setIsSpinnerRuning && setIsSpinnerRuning(false));
};
