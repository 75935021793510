import { Input, Link } from "@chakra-ui/react";
import { InformationBlock } from "../../InformationBlock/InformationBlock";
import { InformationItem } from "../../InformationBlock/InformationItem";
import { StatusCell } from "../../StatusCell/StatusCell";
import { RootState, useAppSelector } from "../../../store";
import { useEffect } from "react";
import { useFormik } from "formik";
import dayjs from "dayjs";
import { reverseDate } from "../../../helpers/reverseDate";
import { InformationItemForm } from "../../InformationBlock/InformationItemForm";
import { numberWithDate } from "../../../helpers/numberWithDate";

export function SingleOrderLetterInformationBlock({ formik, setFieldValue }: {
  formik: any;
  setFieldValue: any
}) {

  const { currentDirectionLetter } = useAppSelector(
    (state: RootState) => state.directionLetters
  );

    
  const isDisabled = currentDirectionLetter?.has_approved_visa || currentDirectionLetter?.status !== "Рабочий" ;

  useEffect(() => {
    setFieldValue(
      "relation_direction_letter_date",
      currentDirectionLetter
        ? dayjs(reverseDate(currentDirectionLetter?.relation_direction_letter_date)).format(
            "YYYY-MM-DD"
          )
        : ""
    );

    setFieldValue("relation_direction_letter_name", currentDirectionLetter?.relation_direction_letter_name ?? "" )
    setFieldValue("amount", Number(currentDirectionLetter?.amount) ?? "" )
    setFieldValue("tax", Number(currentDirectionLetter?.tax) ?? "" )
  }, [setFieldValue, currentDirectionLetter]);
  
  return (
    <InformationBlock>
      <InformationItem title="Статус">
        <StatusCell
          value={currentDirectionLetter?.status ?? ""}
          color="#469DDD40"
        />
      </InformationItem>
      <InformationItem title="Статус оплаты">
        <StatusCell
          value={
            typeof currentDirectionLetter?.paid_status === "string"
              ? currentDirectionLetter?.paid_status
              : ""
          }
          color="#AAE2DB"
        />
      </InformationItem>
      <InformationItem title="Номер">
        {numberWithDate(currentDirectionLetter?.number, reverseDate(currentDirectionLetter?.created_date))}
      </InformationItem>
      <InformationItem title="Подрядчик" isEdited={!isDisabled}>
        {currentDirectionLetter?.organization_name}
      </InformationItem>
      <InformationItem title="Поставщик">
        {currentDirectionLetter?.supplier_name}
      </InformationItem>
      <InformationItem title="ИНН поставщика">
        {currentDirectionLetter?.supplier_inn}
      </InformationItem>
      <InformationItem title="КПП поставщика">
        {currentDirectionLetter?.supplier_kpp}
      </InformationItem>
      <InformationItem title="Договор">
        <Link
          color="#2AB6A5"
          href={`/my-documents/contracts/${currentDirectionLetter?.contract_id}`}
        >
          {currentDirectionLetter?.contract_name}
        </Link>
      </InformationItem>
      <InformationItem title="Организация">
        {currentDirectionLetter?.a101_org_name}
      </InformationItem>
      <InformationItem title="Распорядительное письмо">
        <InformationItemForm
          isDisabled={isDisabled}
          firstId="relation_direction_letter_name"
          secondId="relation_direction_letter_date"
          firstValue={formik.values.relation_direction_letter_name}
          secondValue={formik.values.relation_direction_letter_date}
          onChange={formik.handleChange}
        />
      </InformationItem>
      <InformationItem title="Сумма">
        <Input
          id="amount"
          name="amount"
          h="36px"
          rounded="md"
          borderColor="#E3E7EF"
          placeholder="Введите значение"
          position="absolute"
          top="50%"
          isDisabled={isDisabled}
          transform="translate(0, -50%)"
          onChange={formik.handleChange}
          value={formik.values.amount}
          type="number"
        />
      </InformationItem>
      <InformationItem title="НДС">
        <Input
          id="tax"
          name="tax"
          h="36px"
          rounded="md"
          borderColor="#E3E7EF"
          placeholder="Введите значение"
          position="absolute"
          top="50%"
          isDisabled={isDisabled}
          transform="translate(0, -50%)"
          onChange={formik.handleChange}
          value={formik.values.tax}
          type="number"
        />
      </InformationItem>
      <InformationItem title="Лимитно-заборная карта">
        {currentDirectionLetter?.limit_card_number}
      </InformationItem>
    </InformationBlock>
  );
}
