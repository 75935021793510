import { useAppSelector } from "../../../store";
import { Flex, Text } from "@chakra-ui/react";
import { BuildingObjectsTable } from "./BuildingObjectsTable";
import {useEffect, useState} from "react";
import { formatAmount } from "../../../helpers/formatAmount";

export function BuildingObjectsBlock() {
  const { currentDetails } = useAppSelector((state) => state.estimates);
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    const totalSum = currentDetails.reduce((sum: number, detail) => {
      const quantity = Number(detail.quantityinthedocument) || 0;
      const price = Number(detail.Price) || 0;
      return sum + quantity * price;
    }, 0);

    const fixedTotalSum = totalSum.toFixed(2)
    setTotal(Number(fixedTotalSum));
  }, [currentDetails]);

  return (
    <Flex direction="column">
      <Text h="40px" textAlign="end" fontWeight={500}>
        ИТОГО: {formatAmount(total)}
      </Text>
      <BuildingObjectsTable />
    </Flex>
  );
}
