import { Input, Link } from "@chakra-ui/react";
import { InformationBlock } from "../InformationBlock/InformationBlock";
import { InformationItem } from "../InformationBlock/InformationItem";
import { InformationItemForm } from "../InformationBlock/InformationItemForm";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState, useAppSelector } from "../../store";
import { useEffect } from "react";

export function CreateOrderLetterComponent({ formik, setFieldValue }: {
  formik: any,
  setFieldValue: any,
}) {
  const { currentSupplier } = useAppSelector((state: RootState) => state.supplier);
  const { currentLimitFenceCard } = useAppSelector((state: RootState) => state.limitFenceCards);
  const { currentContract } = useAppSelector((state: RootState) => state.contracts);
  const { profileData } = useAppSelector((state: RootState) => state.profile);


  return (
    <InformationBlock>
      <InformationItem title="Статус"></InformationItem>
      <InformationItem title="Статус оплаты"></InformationItem>
      <InformationItem title="Номер"></InformationItem>
      <InformationItem title="Подрядчик" isEdited>
        {profileData.profile?.organization.org_name}
      </InformationItem>
      <InformationItem title="Поставщик">{currentSupplier?.name}</InformationItem>
      <InformationItem title="ИНН поставщика">{currentSupplier?.inn}</InformationItem>
      <InformationItem title="КПП поставщика">{currentSupplier?.kpp}</InformationItem>
      <InformationItem title="Договор">
        <Link color="#2AB6A5">
          {currentLimitFenceCard?.contract_name ?? currentContract?.name}
        </Link>
      </InformationItem>
      <InformationItem title="Организация">
      {currentLimitFenceCard?.organization_name ?? currentContract?.organization.org_name}
      </InformationItem>  
      <InformationItem title="Распорядительное письмо">
        <InformationItemForm
          firstId="relation_direction_letter_name"
          secondId="relation_direction_letter_date"
          firstValue={formik.values.relation_direction_letter_name}
          secondValue={formik.values.relation_direction_letter_date}
          onChange={formik.handleChange}
          secondLabel="от" 
        />
      </InformationItem>
      <InformationItem title="Сумма">
        <Input
          id="amount"
          name="amount"
          h="36px"
          rounded="md"
          borderColor="#E3E7EF"
          placeholder="Введите значение"
          position="absolute"
          type="number"
          top="50%"
          transform="translate(0, -50%)"
          value={formik.values.amount}
          onChange={formik.handleChange}
          isInvalid={formik.values.amount === ""}
        />
      </InformationItem>
      <InformationItem title="НДС">
        <Input
          id="tax"
          name="tax"
          h="36px"
          rounded="md"
          type="number"
          borderColor="#E3E7EF"
          placeholder="Введите значение"
          position="absolute"
          top="50%"
          transform="translate(0, -50%)"
          value={formik.values.tax}
          onChange={formik.handleChange}
          isInvalid={formik.values.tax === ""}
        />
      </InformationItem>
      <InformationItem title="Лимитно-заборная карта">
        {currentLimitFenceCard?.number}
      </InformationItem>
    </InformationBlock>
  );
}