import { createSlice } from "@reduxjs/toolkit";
import api from "../../api";
import {
  IRequirement,
  IRequirementsListResponse,
} from "../../api/requirements/types";
import { ICommonInitialState } from "../../shared/types/storeTypes";
import { createAsyncThunkInstance } from "../createAsyncThunkInstance";
import { IPrintFormResponse } from "../../api/directionLetters/types";

interface IReportsInitialState extends ICommonInitialState {
  requirementsList: IRequirement[];
}

const initialState: IReportsInitialState = {
  requirementsList: [],
  isLoading: false,
  error: null,
};

export const fetchRequirementsList = createAsyncThunkInstance<
  IRequirementsListResponse,
  undefined
>("requirements/fetchRequirementsList", api.requirements.getRequirementsList);

export const fetchReqPrintForm = createAsyncThunkInstance<
  IPrintFormResponse,
  string
>("requirements/fetchReqPrintForm", api.requirements.getPrintForm);

const requirementsSlice = createSlice({
  name: "savingsCompensationActs",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchRequirementsList.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchRequirementsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.requirementsList = action.payload.data;
      });
  },
});

export const requirementsReducer = requirementsSlice.reducer;
