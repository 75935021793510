import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { sendTechSupport } from "../../../api/feedback";
import { ModalHeader } from "./ModalHeader/ModalHeader";
import { ModalFooter } from "./ModalFooter/ModalFooter";
import { OutlineButton, PrimaryButton } from "../../lib/button/Button";
import { DropFileInput } from "../../lib/input/drop-file-input/DropFileInput";
import { Modal, ModalProps } from "../Modal";
import { TextArea } from "../../lib/textarea/TextArea";
import { SuccessMessage } from "./ExecutorMessageModal";
import { ErrorMessage } from "../../ErrorMessage/ErrorMessage";
import styles from ".././modal.module.scss";

type TechSupportModalProps = Omit<ModalProps, "children"> & {
  title: string;
  setSuccessSupportSend: (arg: boolean) => void;
  confirmationLoss: boolean;
  setConfirmationLoss: (arg: boolean) => void;
};

export function TechSupportModal(props: TechSupportModalProps) {
  const { active, onClose, title, setSuccessSupportSend, confirmationLoss, setConfirmationLoss } = props;

  const [techSupportError, setTechSupportError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);

  const handleCloseModal = () => {
    onClose();
    setSuccess(false);
  };

  const onSubmit = () => {
    let formData = new FormData();

    formik.values.attachments.forEach((attachment) => {
      formData.append("attachments[]", attachment);
    });
    formData.append("message", formik.values.message);

    sendTechSupport(formData)
      .then(() => {
        setSuccess(true);
        setSuccessSupportSend(true);
        formik.resetForm();
      })
      .catch((error) => setTechSupportError(error.message));
  };

  const formik = useFormik({
    initialValues: {
      message: "",
      attachments: [],
    },
    validationSchema: Yup.object({
      message: Yup.string().required(),
      attachments: Yup.mixed(),
    }),
    onSubmit,
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnMount: true,
  });

  const handleFileInputChange = (files: File[]) => {
    formik.setFieldValue("attachments", files);
  };

  useEffect(() => {
    setConfirmationLoss(false);
    if (
      formik.values.message === "" &&
      formik.values.attachments.length === 0
    ) {
      setSuccessSupportSend(true);
    } else {
      setSuccessSupportSend(false);
    }
  }, [formik.values]);

  useEffect(() => {
    confirmationLoss && formik.resetForm();
  }, [confirmationLoss])

  useEffect(() => {
    active && setSuccessSupportSend(true);
  }, [active])

  return (
    <Modal active={active} onClose={handleCloseModal}>
      <div
        className={styles.modal_content}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <div className={styles.container}>
          <ModalHeader title={title} onClose={handleCloseModal} />
          <form className={styles.form} onSubmit={formik.handleSubmit}>
            {success ? (
              <SuccessMessage onClose={handleCloseModal} />
            ) : (
              <>
                <div className={styles.modal_info}>
                  <TextArea
                    label="Текст сообщения"
                    id="message"
                    name="message"
                    placeholder="Введите текст сообщения"
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    error={formik.touched.message && !!formik.errors.message}
                  />
                  <DropFileInput
                    text="Перетащите сюда файл(ы)"
                    label="Загрузка файлов"
                    name="attachments"
                    onChange={handleFileInputChange}
                  />

                  {techSupportError && (
                    <ErrorMessage message={techSupportError} />
                  )}
                </div>

                <ModalFooter>
                  <OutlineButton onClick={onClose}>Отмена</OutlineButton>
                  <PrimaryButton type="submit">Отправить</PrimaryButton>
                </ModalFooter>
              </>
            )}
          </form>
        </div>
      </div>
    </Modal>
  );
}
