import {TableHTMLAttributes, useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  flexRender,
  ColumnDef,
  ExpandedState,
  getExpandedRowModel,
  ColumnFiltersState,
  Row,
  RowSelectionState, SortingState,
} from "@tanstack/react-table";
import { Box, Flex, Spinner } from "@chakra-ui/react";
import classNames from "classnames";
import { MemoizedFilter } from "../../Filter/Filter";
import { SortIcon } from "../../SortIcon/SortIcon";
import styles from "./table.module.scss";
import { AsyncThunk, Dispatch } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { useAppDispatch } from "../../../store";
import { NoMatches } from "../../NoMatches/NoMatches";


interface TableProps extends TableHTMLAttributes<HTMLTableElement> {
  data: Array<any>;
  columns: ColumnDef<any, string>[];
  rowClick?: (row: Row<any>) => void;
  enableMultiRowSelection?: boolean;
  onRowSelect?: (row: Row<any>) => void;
  dblClickDisabled?: boolean;
  dblClickHandler?: (id: number, row?: Row<any>) => void;
  defaultSorting?: SortingState;
  isGuid?: boolean;
  isWorkCompletedActs?: boolean;
  maxHeight?: string;
  minHeight?: string;
  selectedItems?: Array<any>;
  makeTableWrap?: () => void;
  lastViewedDocument?: string | number;
  fetchFunction?: AsyncThunk<any, undefined, {
    rejectValue: AxiosError;
    state?: unknown;
    dispatch?: Dispatch | undefined;
    extra?: unknown;
    serializedErrorType?: unknown;
    pendingMeta?: unknown;
    fulfilledMeta?: unknown;
    rejectedMeta?: unknown;
}>
}

export function Table(props: Readonly<TableProps>) {
  const {
    data,
    columns,
    rowClick,
    enableMultiRowSelection = false,
    dblClickDisabled,
    isGuid,
    isWorkCompletedActs,
    dblClickHandler,
    defaultSorting = [],
    maxHeight = "70vh",
    minHeight = "auto",
    selectedItems = [],
    makeTableWrap,
    fetchFunction,
    lastViewedDocument,
    ...rest
  } = props;
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [sorting, setSorting] = useState<SortingState>(defaultSorting);
  const [loadingSpinner, setLoadingSpinner] = useState<boolean>(true)
  const tableRef = useRef<HTMLDivElement | null>(null);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const table = useReactTable({
    data: data,
    columns: columns,
    enableRowSelection: true,
    enableMultiRowSelection,
    defaultColumn: {
      enableColumnFilter: false,
      enableSorting: false,
    },
    state: {
      sorting,
      expanded,
      columnFilters,
      rowSelection,
    },
    onExpandedChange: setExpanded,
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onSortingChange: setSorting,
  });

  function handleRowClick<T>(row: Row<T>): void {
    if (rowClick) {
      rowClick(row);
    }
  }

  function handleRowDoubleClick<T>(id: number, row?: Row<T>):void {
    dblClickHandler ? dblClickHandler(id, row) : (!dblClickDisabled && navigate(`${id}`));
  }

  function handleDoubleClickCompletedWorkAct<T>(id: number, row?: Row<T>) {
    dblClickHandler ? dblClickHandler(id, row) : (!dblClickDisabled && navigate(`/my-documents/contracts/work-completed-act/${id}`))
  }

  useEffect(() => {
    if (tableRef.current) {
      makeTableWrap && tableRef.current?.scrollHeight > tableRef.current?.clientHeight && makeTableWrap();
    }
  }, [makeTableWrap, tableRef.current?.scrollHeight, tableRef.current?.clientHeight])

  const [visibleRows, setVisibleRows] = useState<Row<any>[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [tableWidth, setTableWidth] = useState<number>()
  const [columnsLength, setColumnsLength] = useState<number>(0)
  const [lastElement, setLastElement] = useState(lastViewedDocument)
  const [scrollWithLastElement, setScrollWithLastElement] = useState<boolean>(lastViewedDocument ? false : true)
  const rowsPerPage = 100;

  useEffect(() => {
    const allRows = table.getRowModel().rows;
    if (scrollWithLastElement) {
      const startIndex = 0;
      const middleIndex = (currentPage - 1) * rowsPerPage;
      const endIndex = middleIndex + rowsPerPage;
      setVisibleRows(allRows.slice(startIndex, endIndex));
    } else {
      const foundIndex = allRows.findIndex(row => 
        row.original.id === lastElement || row.original.guid === lastElement
      );
      setVisibleRows(allRows.slice(0, foundIndex + 20));
      setCurrentPage(Math.ceil(visibleRows.length / rowsPerPage))
    }
  }, [currentPage, table.getRowModel().rows]);

  useEffect(() => {
    if (lastElement && !scrollWithLastElement) {
      const targetElement = tableRef.current?.querySelector(`
        tr[data-row-id="${lastElement}"]
      `);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "auto", block: "center" });
        setLastElement("")
      }
    } 
  });

  const handleScroll = (event:React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement; 
    const { scrollTop, scrollHeight, clientHeight } = target;
    if (scrollTop + clientHeight >= scrollHeight - 100) {
      setScrollWithLastElement(true)
      setCurrentPage(currentPage + 1);
    }
  };

  const checkTableSize = () => {
    if (tableRef.current) {
      setTableWidth(tableRef.current.offsetWidth - 64)
    }
  };

  useEffect(() => {
    checkTableSize();

    window.addEventListener('resize', checkTableSize);

    return () => {
      window.removeEventListener('resize', checkTableSize);
      setColumnsLength(0)
    };
  }, []);

  useEffect(() => {
    table.getHeaderGroups().map((el) =>
      setColumnsLength((prevLength) => prevLength + el.headers.length)
    )
  }, [table])

  const renderTableBody = () => {
    if (loadingSpinner) {
      return (
        <tr className={styles.tr__withoutHover}>
          <td colSpan={table.getHeaderGroups()[0].headers.length}>
            <Flex
              width="100%"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              height="510px"
            >
              <Spinner size="xl" />
            </Flex>
          </td>
        </tr>
      );
    }

    return visibleRows.length > 0 ? (
      visibleRows.map((row) => (
        <tr
          key={row.id}
          data-row-id={row.original.id ?? row.original.guid}
          className={classNames(
            {
              [styles.lastViewedDocument]:
                (row.original.id && row.original.id === lastViewedDocument)
                || (row.original.guid && row.original.guid === lastViewedDocument)
                
            },
            {
              [styles.checked]:
                row.getIsSelected() ||
                row.getIsExpanded() ||
                row.depth > 0,
            },
            {
              [styles.reverse]:
                row.original.reverse === true || row.original.hours < 0,
            },
            {
              [styles.checkedAdditionalRequest]:
                row.original.hours < 0 &&
                (row.getIsSelected() ||
                  row.getIsExpanded() ||
                  row.depth > 0),
            },
            {
              [styles.reverseCheked]:
                row.original.reverse === true &&
                selectedItems?.includes(row.original),
            }
          )}
          onClick={() => handleRowClick(row)}
          onDoubleClick={() => {
            if (isGuid) {
              handleRowDoubleClick(row.original.guid, row);
            } else if (isWorkCompletedActs) {
              handleDoubleClickCompletedWorkAct(
                row.original.id, row
              );
            } else {
              handleRowDoubleClick(row.original.id);
            }
          }}
        >
          {row.getVisibleCells().map((cell) => (
            <td
              key={cell.id}
              className={
                cell.column.columnDef.meta?.cellClassName &&
                styles[cell.column.columnDef.meta?.cellClassName]
              }
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      ))
    ) : (
      <tr className={styles.tr__withoutHover}>
        <td colSpan={columnsLength}>
          <div style={{
            width: tableWidth,
          }}>
            <NoMatches />
          </div>
        </td>
      </tr>
    );
  };

  useEffect(() => {
    fetchFunction ?
      dispatch(fetchFunction())
        .finally(() => setLoadingSpinner(false))
      : setLoadingSpinner(false)
  }, [dispatch, fetchFunction]);

  return (
    <Box minHeight={minHeight} maxHeight={maxHeight} ref={tableRef}>
      <div className={styles.main_table_container} {...rest}>
        <div style={{
          minHeight: minHeight,
          maxHeight: maxHeight
        }} onScroll={handleScroll} className={styles.main_table_container_container}>
          <table>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id} colSpan={header.colSpan}>
                      <Flex
                        gap="10px"
                        justify="space-between"
                        className={styles.inner_head_cell}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                        {header.column.getCanSort() && (
                          <div
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            <SortIcon sort={header.column.getIsSorted()} />
                          </div>
                        )}
                        {header.column.getCanFilter() &&
                          !header.column.columnDef.meta?.filterSelect && (
                            <MemoizedFilter
                              column={header.column}
                              table={table}
                            />
                          )}
                      </Flex>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {renderTableBody()}
            </tbody>
          </table>
        </div>
      </div>
    </Box>
  );
}
