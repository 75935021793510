import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import api from "../../api";
import { RootState, isError } from "..";
import { resetProfile } from "./profile";
import {
  setIsOnboardingСompleted,
  // setOnboardingPermissions,
} from "./onboarding";
import {
  IAdminLoginRequest,
  ILoginRequest,
  ILoginResponse,
  IPasswordChangeRequest,
  IPasswordChangeResponse,
  IPasswordEmailRequest,
  IPasswordEmailResponse,
  IPasswordResetRequest,
  IPasswordResetResponse,
} from "../../api/auth/types";
import {getPermissionsFromLS} from "../../helpers/getPermissionsFromLS";
import { IOnboardingScreen } from "../../api/onboarding/types";
import { AccessRights } from "../../shared/mock/sidebarContent";
import { getExistOnboardingFromLS } from "../../helpers/getExistOnboardingFromLS";

export interface AuthState {
  authData: {
    token: string | null;
    permissions: string[] | null;
    isLoading: boolean;
    loginError: string | null;
    emailError: string | null;
    changePasswordError: string | null;
    commonError: string | null;
    existOnboarding: AccessRights[];
  };
}

export const loginUser = createAsyncThunk<
  ILoginResponse,
  ILoginRequest,
  { rejectValue: string }
>("auth/login", async (params, { rejectWithValue, dispatch }) => {
  try {
    const { data } = await api.auth.login(params);
    dispatch(setIsOnboardingСompleted(data.data.onboarding_completed));

    // dispatch(setOnboardingPermissions(data.data.onboarding_permissions));

    return data;
  } catch (error: unknown) {
    console.error(error);
    throw rejectWithValue("Неверный логин или пароль");
  }
});

export const adminLogin = createAsyncThunk<
  ILoginResponse,
  IAdminLoginRequest,
  { rejectValue: string }
>("auth/admin-login", async (params, { rejectWithValue, dispatch }) => {
  try {
    const { data } = await api.auth.adminLogin(params);
    dispatch(setIsOnboardingСompleted(data.data.onboarding_completed));
    return data;
  } catch (error: unknown) {
    console.error(error);
    throw rejectWithValue("Неверный логин или пароль");
  }
});

export const logoutUser = createAsyncThunk<
  string,
  undefined,
  { rejectValue: string }
>("auth/logout", async (_, { rejectWithValue, dispatch }) => {
  try {
    const { data } = await api.auth.logout();
    dispatch(resetProfile());

    return data.message;
  } catch (error: unknown) {
    console.error(error);
    throw rejectWithValue("Не удалось выйти");
  }
});

export const emailUserPassword = createAsyncThunk<
  IPasswordEmailResponse,
  IPasswordEmailRequest,
  { rejectValue: string }
>("auth/emailPassword", async (params, { rejectWithValue }) => {
  try {
    const { data } = await api.auth.emailPassword(params);

    return data;
  } catch (error) {
    throw rejectWithValue("Данный e-mail не найден");
  }
});

export const resetUserPassword = createAsyncThunk<
  IPasswordResetResponse,
  IPasswordResetRequest,
  { rejectValue: string }
>("auth/resetPassword", async (params, { rejectWithValue }) => {
  try {
    const { data } = await api.auth.resetPassword(params);

    return data;
  } catch (error) {
    console.error(error);
    throw rejectWithValue("Не удалось создать новый пароль");
  }
});

export const changeUserPassword = createAsyncThunk<
  IPasswordChangeResponse,
  IPasswordChangeRequest,
  { rejectValue: string }
>("auth/changePassword", async (params, { rejectWithValue }) => {
  try {
    const { data } = await api.auth.changePassword(params);

    return data;
  } catch (error: unknown) {
    console.error(error);
    throw rejectWithValue("Не удалось сменить пароль");
  }
});

const initialState: AuthState = {
  authData: {
    token: null,
    permissions: getPermissionsFromLS(),
    isLoading: false,
    loginError: null,
    emailError: null,
    changePasswordError: null,
    commonError: null,
    existOnboarding: getExistOnboardingFromLS(),
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.authData = {
          ...state.authData,
          isLoading: true,
          loginError: null,
        };
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        localStorage.setItem("token", action.payload.data.token);
        localStorage.setItem("permissions", JSON.stringify(action.payload.data.permissions));
        localStorage.setItem("existOnboarding", JSON.stringify(action.payload.data.exist_onboarding));

        state.authData = {
          ...state.authData,
          token: action.payload.data.token,
          permissions: action.payload.data.permissions,
          existOnboarding: action.payload.data.exist_onboarding,
          isLoading: false,
          loginError: null,
        };
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.authData.isLoading = false;
        state.authData.loginError = action.payload!;
      })
      .addCase(adminLogin.pending, (state) => {
        state.authData = {
          ...state.authData,
          isLoading: true,
          loginError: null,
        };
      })
      .addCase(adminLogin.fulfilled, (state, action) => {
        localStorage.setItem("token", action.payload.data.token);
        localStorage.setItem("permissions", JSON.stringify(action.payload.data.permissions));
        localStorage.setItem("existOnboarding", JSON.stringify(action.payload.data.exist_onboarding));

        state.authData = {
          ...state.authData,
          token: action.payload.data.token,
          permissions: action.payload.data.permissions,
          existOnboarding: action.payload.data.exist_onboarding,
          isLoading: false,
          loginError: null,
        };
      })
      .addCase(adminLogin.rejected, (state, action) => {
        state.authData.isLoading = false;
        state.authData.loginError = action.payload!;
      })

      .addCase(logoutUser.pending, (state) => {
        state.authData = {
          ...state.authData,
          isLoading: true,
        };
      })
      .addCase(logoutUser.fulfilled, (state) => {
        localStorage.removeItem("token");
        localStorage.removeItem("permissions");
        localStorage.removeItem("existOnboarding");

        state.authData = {
          ...state.authData,
          token: null,
          permissions: null,
          isLoading: false,
        };
      })

      .addCase(emailUserPassword.pending, (state) => {
        state.authData = {
          ...state.authData,
          isLoading: true,
        };
      })
      .addCase(emailUserPassword.fulfilled, (state) => {
        state.authData = {
          ...state.authData,
          isLoading: false,
          emailError: null,
        };
      })
      .addCase(emailUserPassword.rejected, (state, action) => {
        state.authData = {
          ...state.authData,
          isLoading: false,
          emailError: action.payload!,
        };
      })

      .addCase(resetUserPassword.pending, (state) => {
        state.authData = {
          ...state.authData,
          isLoading: true,
        };
      })
      .addCase(resetUserPassword.fulfilled, (state) => {
        state.authData = {
          ...state.authData,
          isLoading: false,
        };
      })

      .addCase(changeUserPassword.pending, (state) => {
        state.authData.isLoading = true;
      })
      .addCase(changeUserPassword.fulfilled, (state) => {
        state.authData.isLoading = false;
        state.authData.changePasswordError = null;
      })
      .addCase(changeUserPassword.rejected, (state, action) => {
        state.authData.isLoading = false;
        state.authData.changePasswordError = action.payload!;
      })

      .addMatcher(isError, (state, action: PayloadAction<string>) => {
        state.authData.isLoading = false;
        state.authData.commonError = action.payload;
      });
  },
});

export const selectIsAuth = (state: RootState) =>
  Boolean(state.auth.authData.token);
export const authReducer = authSlice.reducer;
