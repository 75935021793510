import {
  Flex, Image, ModalBody, ModalContent, ModalOverlay, Text, Textarea, useDisclosure, Modal, ModalHeader, ModalFooter, MenuList, MenuItem, Menu
} from "@chakra-ui/react";
import {Title} from "../../components/lib/title/Title";
import {MainLayout} from "../../components/MainLayout/MainLayout";
import {OutlineButton, PrimaryButton} from "../../components/lib/button/Button";
import React, {useEffect, useState} from "react";
import {RootState, useAppDispatch, useAppSelector} from "../../store";
import {ReactComponent as File} from "../../images/svg/file/file-red.svg";
import {DownloadFilesModal} from "../../components/Modal/ModalRight/DownloadFilesModal";
import {AddRequestTable} from "../../components/AddRequestTable/AddRequestTable";
import {
  addAdditionalRequest,
  answerSingleAdditionalRequest, approveSingleAdditionalRequest,
  attachFilesToSingleAdditionalRequest,
  fetchAdditionalRequestFiles,
  fetchAdditionalRequests,
} from "../../store/slices/additionalRequests";
import {IFile, IFilesAttachResponse} from "../../api/files/types";
import {ChakraModalProps} from "../../shared/types/modalProps";
import close from "../../images/svg/close/close.svg";
import {useFormik} from "formik";
import {IAnswerAdditionalRequestRequest, ICreateAdditionalRequestRequest} from "../../api/additionalRequests/types";
import { OutlineMenuButton } from "../../components/lib/menu/ChakraMenuButton";
import { ChevronDownIcon } from "@chakra-ui/icons";

interface IAnswerModal extends ChakraModalProps {
  setTextToSuccessModal: (text: string) => void;
  onSuccessModalOpen: () => void;
}

interface IApproveModal extends ChakraModalProps {
  setTextToSuccessModal: (text: string) => void;
  onSuccessModalOpen: () => void;
}

interface ISuccessModal extends ChakraModalProps {
  text: string;
}

interface ISendAddRequestModal extends ChakraModalProps {
  setTextToSuccessModal: (text: string) => void;
  onSuccessModalOpen: () => void;
}

export function AddRequestPage() {
  const [filesModal, setFilesModal] = useState<boolean>(false);
  const [files, setFiles] = useState<IFile[]>([]);
  const [successModalText, setSuccessModalText] = useState("");
  const {currentAdditionalRequest} = useAppSelector((state: RootState) => state.additionalRequests);
  const dispatch = useAppDispatch();
  const {isOpen, onClose, onOpen} = useDisclosure();
  const {isOpen: isSuccessModalOpen, onClose: onSuccessModalClose, onOpen: onSuccessModalOpen} = useDisclosure();
  const {isOpen: isApproveModalOpen, onClose: onApproveModalClose, onOpen: onApproveModalOpen} = useDisclosure();
  const {isOpen: isSendAddRequestModalOpen, onClose: onSendAddRequestModalClose, onOpen: onSendAddRequestModalOpen} = useDisclosure();
  
  const handleFilesBtnClick = () => {
    currentAdditionalRequest && dispatch(fetchAdditionalRequestFiles(currentAdditionalRequest?.guid)).then(res => {
      setFiles((res.payload as IFilesAttachResponse)?.data.files);
    });
    setFilesModal(true);
  }

  const fileAttach = (files: File[]) => {
    if (currentAdditionalRequest) {
      dispatch(attachFilesToSingleAdditionalRequest({id: currentAdditionalRequest.guid, files: files})).then(res => {
        setFiles((res.payload as IFilesAttachResponse)?.data.files);
      });
    }
  }

  const setTextToSuccessModal = (text: string) => {
    setSuccessModalText(text);
  }

  return (
    <MainLayout>
      <Flex direction="column" gap={4} p="6">
        <Flex justifyContent="space-between">
          <Title>Доп. запросы и Визы</Title>
          <Flex gap="12px" align="center">
            <OutlineButton leftIcon={<File />} onClick={handleFilesBtnClick} isDisabled={!currentAdditionalRequest}>Файлы</OutlineButton>
            <OutlineButton isDisabled={!(currentAdditionalRequest && currentAdditionalRequest.can_create_request)} onClick={onSendAddRequestModalOpen}>Отправить доп. запрос на визу</OutlineButton>
            <PrimaryButton isDisabled={!(currentAdditionalRequest && currentAdditionalRequest.is_visa && currentAdditionalRequest.can_approve)} onClick={onApproveModalOpen}>Согласовать</PrimaryButton>
            <PrimaryButton isDisabled={!(currentAdditionalRequest && !currentAdditionalRequest.is_visa && currentAdditionalRequest.can_answer)} onClick={onOpen}>Ответить</PrimaryButton>
          </Flex>
        </Flex>
        <AddRequestTable onSendAddRequestModalOpen={onSendAddRequestModalOpen} />
      </Flex>
      <DownloadFilesModal
        title="Файлы"
        active={filesModal}
        onClose={() => {
          setFiles([])
          setFilesModal(false)
        }}
        receivedFiles={files}
        fileAttach={fileAttach}
      />
      <AnswerModal isOpen={isOpen} onClose={onClose} onSuccessModalOpen={onSuccessModalOpen} setTextToSuccessModal={setTextToSuccessModal} />
      <ApproveModal isOpen={isApproveModalOpen} onClose={onApproveModalClose} onSuccessModalOpen={onSuccessModalOpen} setTextToSuccessModal={setSuccessModalText} />
      <SuccessModal isOpen={isSuccessModalOpen} onClose={onSuccessModalClose} text={successModalText} />
      <SendAddRequestModal isOpen={isSendAddRequestModalOpen} onClose={onSendAddRequestModalClose} onSuccessModalOpen={onSuccessModalOpen} setTextToSuccessModal={setSuccessModalText} />
    </MainLayout>
  );
}

function AnswerModal({ onClose, isOpen, setTextToSuccessModal, onSuccessModalOpen }: IAnswerModal) {
  const dispatch = useAppDispatch();
  const { currentAdditionalRequest } = useAppSelector((state: RootState) => state.additionalRequests);
  const onSubmit = async (values: IAnswerAdditionalRequestRequest) => {
    if (currentAdditionalRequest) {
      return dispatch(answerSingleAdditionalRequest({ id: currentAdditionalRequest?.guid, params: values }))
        .finally(() => dispatch(fetchAdditionalRequests()))
    }
  };

  const formik = useFormik({
    initialValues: {
      answer: ""
    },

    onSubmit,
  });

  const handleAnswerBtnClick = () => {
    onSubmit(formik.values).then(() => {
      setTextToSuccessModal("Ответ успешно отправлен");
      onClose();
      onSuccessModalOpen();
    });
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)"/>
      <ModalContent maxW="480px" gap="16px" p="24px">
        <ModalHeader p={0}>
          <Flex justifyContent="space-between" align="center">
            <Text
              fontSize="24px"
              color="#343b4c"
              lineHeight="28px"
              letterSpacing="-1.5%"
              fontWeight={500}>
              Ответить
            </Text>
            <Image cursor="pointer" src={close} onClick={onClose} />
          </Flex>
        </ModalHeader>
        <ModalBody p={0}>
          <Textarea h="240px" name="answer" defaultValue={formik.values.answer} onChange={formik.handleChange}/>
        </ModalBody>
        <ModalFooter p={0}>
          <Flex w="100%" gap="8px" align="center">
            <OutlineButton w="full" onClick={onClose}>
              Отмена
            </OutlineButton>
            <PrimaryButton w="full" onClick={handleAnswerBtnClick} isDisabled={formik.values.answer === ""}>
              Ответить
            </PrimaryButton>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function SuccessModal({onClose, isOpen, text}: ISuccessModal) {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)"/>
      <ModalContent maxW="320px" gap="16px" px="24px" py="32px">
        <ModalHeader p={0}>
          <Flex flexDirection="column" gap="12px" align="center">
            <Text
              fontSize="20px"
              color="#343b4c"
              lineHeight="24px"
              letterSpacing="-1.5%"
              fontWeight={500}
              textAlign="center">
              Готово
            </Text>
            <Text color="#5C657E"
              fontSize="16px"
              lineHeight="20px"
              fontWeight={400}
              textAlign="center">
              {text}
            </Text>
          </Flex>
        </ModalHeader>
        <ModalFooter p={0}>
          <PrimaryButton w="full" onClick={onClose}>
            Закрыть
          </PrimaryButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function ApproveModal({onClose, isOpen, setTextToSuccessModal, onSuccessModalOpen}: IApproveModal) {
  const dispatch = useAppDispatch();
  const {currentAdditionalRequest} = useAppSelector((state: RootState) => state.additionalRequests);

  const handleApproveBtnClick = () => {
    if (currentAdditionalRequest) {
      dispatch(approveSingleAdditionalRequest(currentAdditionalRequest.guid)).then(() => {
        setTextToSuccessModal("Виза успешно согласована");
        onClose();
        onSuccessModalOpen();
        dispatch(fetchAdditionalRequests())
      })
    }
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)"/>
      <ModalContent maxW="320px" gap="16px" px="24px" py="32px">
        <ModalHeader p={0}>
          <Flex flexDirection="column" gap="12px" align="center">
            <Text
              fontSize="20px"
              color="#343b4c"
              lineHeight="24px"
              letterSpacing="-1.5%"
              fontWeight={500}
              textAlign="center">
              Готово
            </Text>
            <Text color="#5C657E"
                  fontSize="16px"
                  lineHeight="20px"
                  fontWeight={400}
                  textAlign="center">
              Вы действительно хотите согласовать визу?
            </Text>
          </Flex>
        </ModalHeader>
        <ModalFooter p={0}>
          <Flex w="100%" gap="8px" align="center">
            <OutlineButton w="full" onClick={onClose}>
              Отмена
            </OutlineButton>
            <PrimaryButton w="full" onClick={handleApproveBtnClick}>
              Да
            </PrimaryButton>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function SendAddRequestModal({onClose, isOpen, setTextToSuccessModal, onSuccessModalOpen}: ISendAddRequestModal) {
  const dispatch = useAppDispatch();
  const {currentAdditionalRequest} = useAppSelector((state: RootState) => state.additionalRequests);
  const onSubmit = async (values: ICreateAdditionalRequestRequest) => {
    if (currentAdditionalRequest) {
      return dispatch(addAdditionalRequest({ id: currentAdditionalRequest.guid, params: values }))
        .finally(() => dispatch(fetchAdditionalRequests()))
    }
  };

  const [selectValue, setSelectValue] = useState<string | undefined>();

  const formik = useFormik({
    initialValues: {
      incoming_question: "",
      visa: "",
    },

    onSubmit,
  });

  const handleSendBtnClick = () => {
    onSubmit(formik.values).then(() => {
      setTextToSuccessModal("Доп. запрос на визу успешно отправлен");
      onClose();
      onSuccessModalOpen();
      setSelectValue(undefined);
      formik.values.incoming_question = "";
    });
  };

  useEffect(() => {
    formik.values.visa = selectValue ?? "";
  }, [selectValue]);

  const handleCloseModal = () => {
    onClose();
    setSelectValue(undefined);
    formik.values.incoming_question = "";
  };

  useEffect(() => {
    return () => {
      setSelectValue(undefined)
      formik.values.incoming_question = ""
    }
  },[])

  return (
    <Modal isCentered isOpen={isOpen} onClose={handleCloseModal}>
      <ModalOverlay backdropFilter="blur(3px)"/>
      <ModalContent maxW="480px" gap="16px" p="24px">
        <ModalHeader p={0}>
          <Flex justifyContent="space-between" align="center">
            <Text
              fontSize="24px"
              color="#343b4c"
              lineHeight="28px"
              letterSpacing="-1.5%"
              fontWeight={500}>
              Отправить доп. запрос на визу
            </Text>
            <Image cursor="pointer" src={close} float="right" onClick={handleCloseModal} />
          </Flex>
        </ModalHeader>
        <ModalBody p={0}>
          <Flex flexDirection="column" gap={3}>
            <Textarea
              name="incoming_question"
              h="240px"
              defaultValue={formik.values.incoming_question}
              onChange={formik.handleChange}
              placeholder="Введите текст сообщения"
            />
            <Menu>
              <Text fontSize="14px" lineHeight="18px">
                Выберите визу
              </Text>
              <OutlineMenuButton
                w="100%"
                h="40px"
                rightIcon={<ChevronDownIcon color="#5C657E !important" />}
                textAlign="left"
                color="#5C657E"
                borderColor="#E3E7EF"
                padding="10px 12px"
                fontWeight={400}
                _active={{ bg: "#FFFFFF", borderColor: "#E3E7EF" }}
              >
                <Text textOverflow="ellipsis" overflow="hidden">
                  {selectValue ?? "Выберите из списка"}
                </Text>
              </OutlineMenuButton>
              <MenuList
                border="1px solid #E3E7EF"
                marginTop="-9px"
                borderTopRadius={0}
                p={0}
                maxHeight="256px"
                overflowY="auto"
              >
                {currentAdditionalRequest &&
                  currentAdditionalRequest?.create_request_visas.map((item) => (
                    <MenuItem
                      fontWeight={400}
                      key={item}
                      onClick={() => setSelectValue(item)}
                    >
                      {item}
                    </MenuItem>
                  ))}
              </MenuList>
            </Menu>
          </Flex>
        </ModalBody>
        <ModalFooter p={0}>
          <Flex w="100%" gap="8px" align="center">
            <OutlineButton w="full" onClick={() => {
              onClose();
              setSelectValue(undefined);
              formik.values.incoming_question = "";
            }}>
              Отмена
            </OutlineButton>
            <PrimaryButton
              w="full"
              onClick={handleSendBtnClick}
              isDisabled={
                formik.values.incoming_question === "" ||
                selectValue === undefined
              }
            >
              Отправить
            </PrimaryButton>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
