import React, {useEffect, useState} from "react";
import {Flex, InputGroup} from "@chakra-ui/react";
import {Input} from "../lib/input/Input";
import {InformationItemDateInput} from "../InformationBlock/InformationItemDateInput";
import {InformationBlock} from "../InformationBlock/InformationBlock";
import { RootState, useAppSelector } from "../../store";
import { checkPermission } from "../../helpers/checkPermission";
import { AccessRights } from "../../shared/mock/sidebarContent";
import { IAdvancedPayment, ICreateWorkCompletedActRequest } from "../../api/workCompletedActs/types";
import dayjs from "dayjs";
import { FormikProps } from "formik";
import { reverseDate } from "../../helpers/reverseDate";

interface DocumentsFieldsProps {
  formik?: FormikProps<ICreateWorkCompletedActRequest>;
}

export function DocumentsFields({formik}: DocumentsFieldsProps) {
  const [inputs, setInputs] = useState<IAdvancedPayment[]>([{name:"", date: ""}]);
  const { permissions } = useAppSelector(
    (state: RootState) => state.auth.authData
  );
  const { currentWorkCompletedAct } = useAppSelector((state) => state.workCompletedActs);

  const isDisabledByPermission = !checkPermission(AccessRights.WorkCompletedAct_RW, permissions) || (!!currentWorkCompletedAct && !currentWorkCompletedAct?.can_edit)
  const handleChange = (value: string, fieldName: keyof IAdvancedPayment, index: number) => {
    let onChangeValue = [...inputs];
    if (fieldName === "date") {
      onChangeValue[index][fieldName] = dayjs(value).format("DD.MM.YYYY");
    } else {
      onChangeValue[index][fieldName] = value;
    }
    
    setInputs(onChangeValue)
    if (formik) {
      formik.setFieldValue("advance_payments", inputs)
    }
  }

  useEffect(() => {
    formik?.values.advance_payments && setInputs(formik?.values.advance_payments)
    formik?.values.advance_payments?.length === 0 && setInputs([{name:"", date: ""}])
  },[])


  useEffect(() => {
    if (inputs.length > 0) {
      if (inputs[inputs.length - 1].date && inputs[inputs.length - 1].name) {
        const duplicateArray = [...inputs, { name: "", date: "" }]
        setInputs(duplicateArray)
      }
    }

  }, [inputs])

  return <InformationBlock>
    <Flex direction="column" gap={4}>
      {inputs.map((input, index) => {
        return (
          <InputGroup key={index} gap={4}>
            <Input
              disabled={isDisabledByPermission}
              id={`document_field_name_${index}`}
              name={`document_field_name_${index}`}
              value={inputs[index].name}
              onChange={(event) => handleChange(event.target.value, "name", index)}
              placeholder="Название документа"
              width="100%"
            />
            <InformationItemDateInput
              isDisabled={isDisabledByPermission}
              id={`document_field_date_${index}`}
              name={`document_field_date_${index}`}
              value={inputs[index].date !== "" ? dayjs(reverseDate(inputs[index].date)).format("YYYY-MM-DD") : ""}
              w="240px"
              placeholder="От"
              onChange={(event) => handleChange(event.target.value, "date", index)}
            />
          </InputGroup>
        )
      })}
    </Flex>
  </InformationBlock>
}