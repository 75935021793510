import { AxiosPromise } from "axios";
import { IRequirementsListResponse } from "./types";
import { axiosInstance } from "../instance";
import { RequirementsEndpoints } from "../endpoints";
import { IPrintFormResponse } from "../directionLetters/types";

export const getRequirementsList =
  (): AxiosPromise<IRequirementsListResponse> =>
    axiosInstance.get(RequirementsEndpoints.REQUIREMENTS);


  export const getPrintForm = (id: string): AxiosPromise<IPrintFormResponse> =>
    axiosInstance.post(
      RequirementsEndpoints.REQUIREMENT + `/${id}/request-print-form`
    );